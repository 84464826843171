import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';
import Chat from './Chat';
import Support from './Support';

function HeaderButtons() {
  return (
    <Box>
      {/* <HeaderNotifications />
      <LanguageSwitcher />
      <Chat /> */}
      <Support />
    </Box>
  );
}

export default HeaderButtons;
