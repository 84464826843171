import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { AuthProvider } from './contexts/AmplifyAuthContext';
import { IntercomContext } from './contexts/IntercomContext';
import { IntercomProvider } from 'react-use-intercom';
import { intercomConfig } from './config';
import { amplifyConfig2 } from './config';

import { LicenseInfo } from '@mui/x-license-pro';
// Set the license to all use of Material DateRangePicker
// Dev use expires 02/10/2024
// Prod use is forever
LicenseInfo.setLicenseKey('d28cb5b8e4e0499533bcc23c60e81f98Tz01OTY0NCxFPTE3MDc2OTUxMDA1MzcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const INTERCOM_APP_ID = intercomConfig.app_id

// Moved the auth hook in here after needing to move AuthProvider into App.tsx
function AuthedApp() {
  const content = useRoutes(router);
  const auth = useAuth();
  return auth.isInitialized ? content : <AppInit />
}

function App() {
  const { updateUnreadCount } = useContext(IntercomContext);

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} onUnreadCountChange={updateUnreadCount} >
      <AuthProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <CssBaseline />
                <AuthedApp />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </IntercomProvider>
  );
}
export default App;
