import ReactDOM from 'react-dom';
import 'src/mocks';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { IntercomContextProvider } from './contexts/IntercomContext';

// TODO - commented out all the redux-persist stuff
//import { PersistGate } from 'redux-persist/integration/react';

// NOTE - I removed AuthProvider from this level and moved it into App

ReactDOM.render(
  <IntercomContextProvider>
    <HelmetProvider>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <SidebarProvider>
            <BrowserRouter>
              <ScrollTop />
                <App />
            </BrowserRouter>
          </SidebarProvider>
        {/* </PersistGate> */}
      </Provider>
    </HelmetProvider>
  </IntercomContextProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
