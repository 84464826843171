// Used to allow context access to the Intercom unread message count
import { FC, useState, createContext } from 'react';
type IntercomContext = {
  unreadCount: any;
  updateUnreadCount: (count: number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntercomContext = createContext<IntercomContext>(
  {} as IntercomContext
);

export const IntercomContextProvider: FC = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const updateUnreadCount = (count: number) =>  {
    setUnreadCount(count)
  }

  return (
    <IntercomContext.Provider
      value={{ unreadCount, updateUnreadCount }}
    >
      {children}
    </IntercomContext.Provider>
  );
};