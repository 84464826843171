/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: ClientInput!) {
    updateClient(input: $input) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const updateLinkedClient = /* GraphQL */ `
  mutation UpdateLinkedClient(
    $clientId: ID!
    $clientEmail: String
    $orderShopperId: String
    $orderId: String
    $deliveryTimestamp: String
    $input: LinkedClientInput!
  ) {
    updateLinkedClient(
      clientId: $clientId
      clientEmail: $clientEmail
      orderShopperId: $orderShopperId
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
      input: $input
    ) {
      id
      client {
        id
        firstName
        lastName
        phone
        email
        image
        referralSource
        hasShopper
        signupTimestamp
        attnPassword
        deactivated
        hasStreamUser
        preferences {
          id
          surveys {
            id
            completedTimestamp
            name
            responses {
              id
              question
              response
            }
          }
        }
      }
      note
      orderCount
      lastOrderTimestamp
      firstConnectTimestamp
      referralSourceMessage
      clientAnnotation {
        id
        note
        image
        noteLastUpdatedTimestamp
        preferencesLastUpdatedTimestamp
        preferencesLastSeenTimestamp
        needsToSeePreferences
      }
      lastInviteTimestamp
    }
  }
`;
export const resendWixOrderConfirmation = /* GraphQL */ `
  mutation ResendWixOrderConfirmation(
    $clientId: ID!
    $clientEmail: String
    $input: LinkedOrderIdentifiersInput!
  ) {
    resendWixOrderConfirmation(
      clientId: $clientId
      clientEmail: $clientEmail
      input: $input
    )
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress($input: AddressInput!) {
    createAddress(input: $input) {
      id
      addressName
      addressLine1
      addressLine2
      city
      state
      zipcode
      country
      isDefault
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress($id: ID!, $input: AddressInput!) {
    updateAddress(id: $id, input: $input) {
      id
      addressName
      addressLine1
      addressLine2
      city
      state
      zipcode
      country
      isDefault
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress($id: ID!) {
    deleteAddress(id: $id) {
      id
      addressName
      addressLine1
      addressLine2
      city
      state
      zipcode
      country
      isDefault
    }
  }
`;
export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod($input: PaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      address {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod($id: ID!, $input: PaymentMethodInput!) {
    updatePaymentMethod(id: $id, input: $input) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      address {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
    }
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      address {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
    }
  }
`;
export const createClientOrder = /* GraphQL */ `
  mutation CreateClientOrder($input: OrderInput) {
    createClientOrder(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const createClientOrderV2 = /* GraphQL */ `
  mutation CreateClientOrderV2($input: OrderInputV2) {
    createClientOrderV2(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const clientCancelOrder = /* GraphQL */ `
  mutation ClientCancelOrder($id: ID!, $orderTimestamp: String!) {
    clientCancelOrder(id: $id, orderTimestamp: $orderTimestamp) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const deleteLinkedClient = /* GraphQL */ `
  mutation DeleteLinkedClient($id: ID!) {
    deleteLinkedClient(id: $id) {
      id
      client {
        id
        firstName
        lastName
        phone
        email
        image
        referralSource
        hasShopper
        signupTimestamp
        attnPassword
        deactivated
        hasStreamUser
        preferences {
          id
          surveys {
            id
            completedTimestamp
            name
            responses {
              id
              question
              response
            }
          }
        }
      }
      note
      orderCount
      lastOrderTimestamp
      firstConnectTimestamp
      referralSourceMessage
      clientAnnotation {
        id
        note
        image
        noteLastUpdatedTimestamp
        preferencesLastUpdatedTimestamp
        preferencesLastSeenTimestamp
        needsToSeePreferences
      }
      lastInviteTimestamp
    }
  }
`;
export const inviteClient = /* GraphQL */ `
  mutation InviteClient($input: ClientInput) {
    inviteClient(input: $input) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const inviteClientV2 = /* GraphQL */ `
  mutation InviteClientV2($input: ClientInput) {
    inviteClientV2(input: $input) {
      id
      client {
        id
        firstName
        lastName
        phone
        email
        image
        referralSource
        hasShopper
        signupTimestamp
        attnPassword
        deactivated
        hasStreamUser
        preferences {
          id
          surveys {
            id
            completedTimestamp
            name
            responses {
              id
              question
              response
            }
          }
        }
      }
      note
      orderCount
      lastOrderTimestamp
      firstConnectTimestamp
      referralSourceMessage
      clientAnnotation {
        id
        note
        image
        noteLastUpdatedTimestamp
        preferencesLastUpdatedTimestamp
        preferencesLastSeenTimestamp
        needsToSeePreferences
      }
      lastInviteTimestamp
    }
  }
`;
export const deleteInviteClientV2 = /* GraphQL */ `
  mutation DeleteInviteClientV2($id: ID!) {
    deleteInviteClientV2(id: $id) {
      id
      client {
        id
        firstName
        lastName
        phone
        email
        image
        referralSource
        hasShopper
        signupTimestamp
        attnPassword
        deactivated
        hasStreamUser
        preferences {
          id
          surveys {
            id
            completedTimestamp
            name
            responses {
              id
              question
              response
            }
          }
        }
      }
      note
      orderCount
      lastOrderTimestamp
      firstConnectTimestamp
      referralSourceMessage
      clientAnnotation {
        id
        note
        image
        noteLastUpdatedTimestamp
        preferencesLastUpdatedTimestamp
        preferencesLastSeenTimestamp
        needsToSeePreferences
      }
      lastInviteTimestamp
    }
  }
`;
export const updateShopper = /* GraphQL */ `
  mutation UpdateShopper($input: ShopperInput!) {
    updateShopper(input: $input) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const createShopperIdentity = /* GraphQL */ `
  mutation CreateShopperIdentity($input: IdentityCreateInput!) {
    createShopperIdentity(input: $input) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const updateShopperIdentity = /* GraphQL */ `
  mutation UpdateShopperIdentity($input: IdentityUpdateInput!) {
    updateShopperIdentity(input: $input) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const requestChargeCard = /* GraphQL */ `
  mutation RequestChargeCard(
    $name: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $zipcode: String
    $ipAddr: String
  ) {
    requestChargeCard(
      name: $name
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      state: $state
      zipcode: $zipcode
      ipAddr: $ipAddr
    ) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const updateServicesOrdering = /* GraphQL */ `
  mutation UpdateServicesOrdering($input: ServiceOfferingIdsInput) {
    updateServicesOrdering(input: $input) {
      serviceOfferingIds
    }
  }
`;
export const createServiceOffering = /* GraphQL */ `
  mutation CreateServiceOffering($input: ServiceOfferingInput) {
    createServiceOffering(input: $input) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const updateServiceOffering = /* GraphQL */ `
  mutation UpdateServiceOffering($id: ID, $input: ServiceOfferingInput) {
    updateServiceOffering(id: $id, input: $input) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const deleteServiceOffering = /* GraphQL */ `
  mutation DeleteServiceOffering($id: ID) {
    deleteServiceOffering(id: $id) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const createServiceOfferingV2 = /* GraphQL */ `
  mutation CreateServiceOfferingV2(
    $id: ID
    $serviceName: String
    $serviceImage: String
    $flatFee: String
    $percent: String
    $minFee: String
    $clientPaysPlatform: Boolean
    $preTipPercentages: [String]
    $defaultPercentagesIndex: Int
    $fixedGratuityOptions: [Int]
    $defaultFixedGratuityOptionsIndex: Int
    $useSuggestedPricing: Boolean
  ) {
    createServiceOfferingV2(
      id: $id
      serviceName: $serviceName
      serviceImage: $serviceImage
      flatFee: $flatFee
      percent: $percent
      minFee: $minFee
      clientPaysPlatform: $clientPaysPlatform
      preTipPercentages: $preTipPercentages
      defaultPercentagesIndex: $defaultPercentagesIndex
      fixedGratuityOptions: $fixedGratuityOptions
      defaultFixedGratuityOptionsIndex: $defaultFixedGratuityOptionsIndex
      useSuggestedPricing: $useSuggestedPricing
    ) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const updateServiceOfferingV2 = /* GraphQL */ `
  mutation UpdateServiceOfferingV2(
    $id: ID
    $serviceName: String
    $serviceImage: String
    $flatFee: String
    $percent: String
    $minFee: String
    $clientPaysPlatform: Boolean
    $preTipPercentages: [String]
    $defaultPercentagesIndex: Int
    $fixedGratuityOptions: [Int]
    $defaultFixedGratuityOptionsIndex: Int
    $useSuggestedPricing: Boolean
  ) {
    updateServiceOfferingV2(
      id: $id
      serviceName: $serviceName
      serviceImage: $serviceImage
      flatFee: $flatFee
      percent: $percent
      minFee: $minFee
      clientPaysPlatform: $clientPaysPlatform
      preTipPercentages: $preTipPercentages
      defaultPercentagesIndex: $defaultPercentagesIndex
      fixedGratuityOptions: $fixedGratuityOptions
      defaultFixedGratuityOptionsIndex: $defaultFixedGratuityOptionsIndex
      useSuggestedPricing: $useSuggestedPricing
    ) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const updateDailySchedules = /* GraphQL */ `
  mutation UpdateDailySchedules($input: DailySchedulesInput) {
    updateDailySchedules(input: $input) {
      id
      dailySchedules {
        id
        day
        timeSlots {
          id
          slotStart
          slotEnd
          isActive
          bufferDuration
          bufferMode
        }
      }
    }
  }
`;
export const updateDailySchedulesV2 = /* GraphQL */ `
  mutation UpdateDailySchedulesV2($input: [DailySchedulesInputV2]) {
    updateDailySchedulesV2(input: $input) {
      dailySchedules {
        id
        day
        timeSlots {
          id
          slotStart
          slotEnd
          maxOrders
          bufferDuration
          bufferMode
        }
      }
    }
  }
`;
export const updateScheduleOptions = /* GraphQL */ `
  mutation UpdateScheduleOptions($input: ShopperScheduleOptionsInput) {
    updateScheduleOptions(input: $input) {
      defaultMaxOrders
      defaultTimeSlotDuration
      isScheduleAvailable
      defaultBufferDuration
      defaultBufferMode
    }
  }
`;
export const updateClosedDateRanges = /* GraphQL */ `
  mutation UpdateClosedDateRanges($input: ClosedDateRangesInput) {
    updateClosedDateRanges(input: $input) {
      closedDateRanges {
        id
        startDate
        endDate
        description
      }
    }
  }
`;
export const activateChargeCard = /* GraphQL */ `
  mutation ActivateChargeCard($input: ChargeCardInput!) {
    activateChargeCard(input: $input) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      status
      message
    }
  }
`;
export const createStripeConnectAccount = /* GraphQL */ `
  mutation CreateStripeConnectAccount($input: StripeConnectAccountInput) {
    createStripeConnectAccount(input: $input) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const createStripeConnectAccountV2 = /* GraphQL */ `
  mutation CreateStripeConnectAccountV2($input: StripeConnectAccountInput) {
    createStripeConnectAccountV2(input: $input) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const upgradeStripeConnectAccountFromV1ToV2 = /* GraphQL */ `
  mutation UpgradeStripeConnectAccountFromV1ToV2(
    $input: StripeConnectAccountInput
  ) {
    upgradeStripeConnectAccountFromV1ToV2(input: $input) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const createDirectDepositAccount = /* GraphQL */ `
  mutation CreateDirectDepositAccount($input: DirectDepositAccountInput) {
    createDirectDepositAccount(input: $input) {
      id
      bankName
      accountHolderName
      routingNumber
      last4
      error
      isDefault
    }
  }
`;
export const updateDirectDepositAccount = /* GraphQL */ `
  mutation UpdateDirectDepositAccount(
    $id: ID
    $input: DirectDepositAccountInput
  ) {
    updateDirectDepositAccount(id: $id, input: $input) {
      id
      bankName
      accountHolderName
      routingNumber
      last4
      error
      isDefault
    }
  }
`;
export const deleteDirectDepositAccount = /* GraphQL */ `
  mutation DeleteDirectDepositAccount($id: ID) {
    deleteDirectDepositAccount(id: $id) {
      id
      bankName
      accountHolderName
      routingNumber
      last4
      error
      isDefault
    }
  }
`;
export const updateServiceArea = /* GraphQL */ `
  mutation UpdateServiceArea($input: ServiceAreaInput) {
    updateServiceArea(input: $input) {
      id
      city
      state
      zipcode
      description
    }
  }
`;
export const createServiceTime = /* GraphQL */ `
  mutation CreateServiceTime($input: ServiceTimeInput) {
    createServiceTime(input: $input) {
      dayOfWeek
      startTime
      endTime
    }
  }
`;
export const updateServiceTime = /* GraphQL */ `
  mutation UpdateServiceTime($id: ID, $input: ServiceTimeInput) {
    updateServiceTime(id: $id, input: $input) {
      dayOfWeek
      startTime
      endTime
    }
  }
`;
export const deleteServiceTime = /* GraphQL */ `
  mutation DeleteServiceTime($id: ID) {
    deleteServiceTime(id: $id) {
      dayOfWeek
      startTime
      endTime
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon($input: CouponInput) {
    createCoupon(input: $input) {
      id
      couponCode
      couponValue
      paidByShopper
      validClientIds
      usesPerClient
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon($id: ID, $input: CouponInput) {
    updateCoupon(id: $id, input: $input) {
      id
      couponCode
      couponValue
      paidByShopper
      validClientIds
      usesPerClient
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon($id: ID) {
    deleteCoupon(id: $id) {
      id
      couponCode
      couponValue
      paidByShopper
      validClientIds
      usesPerClient
    }
  }
`;
export const updateShoppingList = /* GraphQL */ `
  mutation UpdateShoppingList($input: ItemsStateInput) {
    updateShoppingList(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const useTransactions = /* GraphQL */ `
  mutation UseTransactions($input: UseTransactionsInput) {
    useTransactions(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const dissociateTransaction = /* GraphQL */ `
  mutation DissociateTransaction($input: UseTransactionsInput) {
    dissociateTransaction(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const addOrderAdjustment = /* GraphQL */ `
  mutation AddOrderAdjustment($input: OrderAdjustmentInput) {
    addOrderAdjustment(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const updateOrderAdjustment = /* GraphQL */ `
  mutation UpdateOrderAdjustment($input: OrderAdjustmentInput) {
    updateOrderAdjustment(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const removeOrderAdjustment = /* GraphQL */ `
  mutation RemoveOrderAdjustment($input: OrderAdjustmentInput) {
    removeOrderAdjustment(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const addOrderAdjustment2 = /* GraphQL */ `
  mutation AddOrderAdjustment2($input: OrderAdjustmentInput) {
    addOrderAdjustment2(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const removeOrderAdjustment2 = /* GraphQL */ `
  mutation RemoveOrderAdjustment2(
    $id: ID!
    $orderId: ID!
    $deliveryTimestamp: String!
  ) {
    removeOrderAdjustment2(
      id: $id
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const updateOrderNotes = /* GraphQL */ `
  mutation UpdateOrderNotes($input: OrderNotesInput!) {
    updateOrderNotes(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const addOrderExternalTransaction = /* GraphQL */ `
  mutation AddOrderExternalTransaction($input: OrderExternalTransactionInput!) {
    addOrderExternalTransaction(input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const removeOrderExternalTransaction = /* GraphQL */ `
  mutation RemoveOrderExternalTransaction(
    $id: ID!
    $input: OrderExternalTransactionRemovalInput!
  ) {
    removeOrderExternalTransaction(id: $id, input: $input) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const completeOrder = /* GraphQL */ `
  mutation CompleteOrder($id: ID!, $deliveryTimestamp: String!) {
    completeOrder(id: $id, deliveryTimestamp: $deliveryTimestamp) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const cancelOrder = /* GraphQL */ `
  mutation CancelOrder($id: ID!, $deliveryTimestamp: String!) {
    cancelOrder(id: $id, deliveryTimestamp: $deliveryTimestamp) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const associateClientWithShopper = /* GraphQL */ `
  mutation AssociateClientWithShopper($input: ReferralLinkInput) {
    associateClientWithShopper(input: $input) {
      linkType
      shopperId
      webPath
      meta
      linkedShopper {
        id
        shopper {
          id
          firstName
          lastName
          displayName
          deliveryArea
          websiteUrl
          phone
          businessPhone
          email
          bio
          image
          businessName
          availabilityDescription
          announcement
          rating
          repeatClientCount
          orderCount
          reviewCount
          specialtyTags
          tagline
          metaTitle
          metaImage
          metaDescription
          deliveryAreaZipcode
          deliveryAreaZipcodes
          deliveryRadius
          lowestPrice
          subscriptionPlan
          subscriptionPlanDetails {
            id
            textComponents {
              text
              bold
            }
            phases {
              id
              status
            }
          }
          branding {
            coverColor
          }
          typicalPricing {
            summary {
              title
              description
            }
            groceries
            shoppingAndDelivery
            minGratuity
            platform
          }
          allowClientConnection
          stripeConnectAcctExists
          fullSSNRequired
          attnBizProfile
          attnDirectDeposit
          attnCreditCard
          attnServices
          attnPassword
          statusIdentityVerification {
            state
            message
            externalLink
          }
          statusChargeCard {
            state
            message
            externalLink
          }
          optInEngage
          optInZipcode
          deactivated
          acceptsReferralNetwork
        }
        serviceOfferings {
          id
          serviceName
          serviceImage
          serviceDescription
          defaultErrandBusinessNames
          baseFee
          maxErrands
          pricingModel {
            version
            description
            flatPm {
              fee
              feeTitle
              display
            }
            hourlyPm {
              rate
              billableHours
              minBillableHours
              feeTitle
              display
            }
            percentPm {
              percent
              minFee
              feeTitle
              display
            }
            preTipPm {
              chosenPercent
              percentages
              defaultPercentagesIndex
              footer
              feeTitle
              display
              fixedGratuityOptions
              defaultFixedGratuityOptionsIndex
              chosenFixedGratuity
              gratuityType
              showPreGratuityByDefault
              allowOptionalGratuity
              showCustomGratuity
            }
            clientPlatformPm {
              percent
              feeTitle
              display
            }
            suggestedFlatPm {
              fee
              feeWarningThreshold
              percentages
              gratuityType
              gratuityOptions
              defaultGratuityOptionsIndex
              clientPaysPlatform
              showCustomGratuity
              title
              description
            }
            suggestedPercentPm {
              percent
              minFee
              percentWarningThreshold
              minFeeWarningThreshold
              gratuityType
              gratuityOptions
              defaultGratuityOptionsIndex
              clientPaysPlatform
              showCustomGratuity
              title
              description
            }
            suggestedFlatPlusPercentPm {
              fee
              feeWarningThreshold
              percent
              minFee
              percentWarningThreshold
              minFeeWarningThreshold
              gratuityType
              gratuityOptions
              defaultGratuityOptionsIndex
              clientPaysPlatform
              showCustomGratuity
              title
              description
            }
          }
          disclaimerText
          useSuggestedPricing
        }
        serviceArea {
          id
          city
          state
          zipcode
          description
        }
        serviceTimes {
          dayOfWeek
          startTime
          endTime
        }
      }
    }
  }
`;
export const associateClientWithShopperV2 = /* GraphQL */ `
  mutation AssociateClientWithShopperV2($input: ReferralLinkInput) {
    associateClientWithShopperV2(input: $input) {
      linkedShopper {
        id
        shopper {
          id
          firstName
          lastName
          displayName
          deliveryArea
          websiteUrl
          phone
          businessPhone
          email
          bio
          image
          businessName
          availabilityDescription
          announcement
          rating
          repeatClientCount
          orderCount
          reviewCount
          specialtyTags
          tagline
          metaTitle
          metaImage
          metaDescription
          deliveryAreaZipcode
          deliveryAreaZipcodes
          deliveryRadius
          lowestPrice
          subscriptionPlan
          subscriptionPlanDetails {
            id
            textComponents {
              text
              bold
            }
            phases {
              id
              status
            }
          }
          branding {
            coverColor
          }
          typicalPricing {
            summary {
              title
              description
            }
            groceries
            shoppingAndDelivery
            minGratuity
            platform
          }
          allowClientConnection
          stripeConnectAcctExists
          fullSSNRequired
          attnBizProfile
          attnDirectDeposit
          attnCreditCard
          attnServices
          attnPassword
          statusIdentityVerification {
            state
            message
            externalLink
          }
          statusChargeCard {
            state
            message
            externalLink
          }
          optInEngage
          optInZipcode
          deactivated
          acceptsReferralNetwork
        }
        serviceOfferings {
          id
          serviceName
          serviceImage
          serviceDescription
          defaultErrandBusinessNames
          baseFee
          maxErrands
          pricingModel {
            version
            description
            flatPm {
              fee
              feeTitle
              display
            }
            hourlyPm {
              rate
              billableHours
              minBillableHours
              feeTitle
              display
            }
            percentPm {
              percent
              minFee
              feeTitle
              display
            }
            preTipPm {
              chosenPercent
              percentages
              defaultPercentagesIndex
              footer
              feeTitle
              display
              fixedGratuityOptions
              defaultFixedGratuityOptionsIndex
              chosenFixedGratuity
              gratuityType
              showPreGratuityByDefault
              allowOptionalGratuity
              showCustomGratuity
            }
            clientPlatformPm {
              percent
              feeTitle
              display
            }
            suggestedFlatPm {
              fee
              feeWarningThreshold
              percentages
              gratuityType
              gratuityOptions
              defaultGratuityOptionsIndex
              clientPaysPlatform
              showCustomGratuity
              title
              description
            }
            suggestedPercentPm {
              percent
              minFee
              percentWarningThreshold
              minFeeWarningThreshold
              gratuityType
              gratuityOptions
              defaultGratuityOptionsIndex
              clientPaysPlatform
              showCustomGratuity
              title
              description
            }
            suggestedFlatPlusPercentPm {
              fee
              feeWarningThreshold
              percent
              minFee
              percentWarningThreshold
              minFeeWarningThreshold
              gratuityType
              gratuityOptions
              defaultGratuityOptionsIndex
              clientPaysPlatform
              showCustomGratuity
              title
              description
            }
          }
          disclaimerText
          useSuggestedPricing
        }
        serviceArea {
          id
          city
          state
          zipcode
          description
        }
        serviceTimes {
          dayOfWeek
          startTime
          endTime
        }
      }
      linkedShopperSchedule {
        id
        dailySchedules {
          id
          day
          timeSlots {
            id
            slotStart
            slotEnd
            isActive
            bufferDuration
            bufferMode
          }
        }
      }
    }
  }
`;
export const connectClientToShopper = /* GraphQL */ `
  mutation ConnectClientToShopper($shopperId: ID!) {
    connectClientToShopper(shopperId: $shopperId) {
      id
      shopper {
        id
        firstName
        lastName
        displayName
        deliveryArea
        websiteUrl
        phone
        businessPhone
        email
        bio
        image
        businessName
        availabilityDescription
        announcement
        rating
        repeatClientCount
        orderCount
        reviewCount
        specialtyTags
        tagline
        metaTitle
        metaImage
        metaDescription
        deliveryAreaZipcode
        deliveryAreaZipcodes
        deliveryRadius
        lowestPrice
        subscriptionPlan
        subscriptionPlanDetails {
          id
          textComponents {
            text
            bold
          }
          phases {
            id
            status
          }
        }
        branding {
          coverColor
        }
        typicalPricing {
          summary {
            title
            description
          }
          groceries
          shoppingAndDelivery
          minGratuity
          platform
        }
        allowClientConnection
        stripeConnectAcctExists
        fullSSNRequired
        attnBizProfile
        attnDirectDeposit
        attnCreditCard
        attnServices
        attnPassword
        statusIdentityVerification {
          state
          message
          externalLink
        }
        statusChargeCard {
          state
          message
          externalLink
        }
        optInEngage
        optInZipcode
        deactivated
        acceptsReferralNetwork
      }
      serviceOfferings {
        id
        serviceName
        serviceImage
        serviceDescription
        defaultErrandBusinessNames
        baseFee
        maxErrands
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        disclaimerText
        useSuggestedPricing
      }
      serviceArea {
        id
        city
        state
        zipcode
        description
      }
      serviceTimes {
        dayOfWeek
        startTime
        endTime
      }
    }
  }
`;
export const createSharedList = /* GraphQL */ `
  mutation CreateSharedList($input: SharedListInput!) {
    createSharedList(input: $input) {
      id
      owner
      listId
      listName
      storeName
      items {
        owner
        listId
        itemId
        catalogId
        position
        name
        image
        unit
        count
        note
        checkedOff
        checkedOffTimestamp
        shopperPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        description
        price
        pricePerUnit
        weight
        weightUnit
        displaySize
        link
        selectedProductOptions {
          name
          selection
        }
      }
      sharedWith
      clientId
      orderId
      orderTimestamp
      serviceImage
    }
  }
`;
export const updateSharedList = /* GraphQL */ `
  mutation UpdateSharedList(
    $owner: ID!
    $listId: ID!
    $input: SharedListInput!
  ) {
    updateSharedList(owner: $owner, listId: $listId, input: $input) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
    }
  }
`;
export const deleteSharedList = /* GraphQL */ `
  mutation DeleteSharedList($owner: ID!, $listId: ID!) {
    deleteSharedList(owner: $owner, listId: $listId) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
    }
  }
`;
export const copySharedList = /* GraphQL */ `
  mutation CopySharedList($owner: ID!, $listId: ID!) {
    copySharedList(owner: $owner, listId: $listId) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
    }
  }
`;
export const addItemToSharedList = /* GraphQL */ `
  mutation AddItemToSharedList(
    $owner: ID!
    $listId: ID!
    $input: SharedListItemInput!
  ) {
    addItemToSharedList(owner: $owner, listId: $listId, input: $input) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
    }
  }
`;
export const updateItemOnSharedList = /* GraphQL */ `
  mutation UpdateItemOnSharedList(
    $owner: ID!
    $listId: ID!
    $itemId: ID!
    $input: SharedListItemInput!
  ) {
    updateItemOnSharedList(
      owner: $owner
      listId: $listId
      itemId: $itemId
      input: $input
    ) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
    }
  }
`;
export const bulkUpdateItemOnSharedList = /* GraphQL */ `
  mutation BulkUpdateItemOnSharedList($input: SharedListBulkUpdateInput!) {
    bulkUpdateItemOnSharedList(input: $input) {
      owner
      listId
      mutationId
      items {
        itemId
        position
        name
        image
        unit
        count
        note
        checkedOff
        checkedOffTimestamp
        shopperPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
      }
    }
  }
`;
export const deleteItemFromSharedList = /* GraphQL */ `
  mutation DeleteItemFromSharedList($owner: ID!, $listId: ID!, $itemId: ID!) {
    deleteItemFromSharedList(owner: $owner, listId: $listId, itemId: $itemId) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
    }
  }
`;
export const shareListByPhone = /* GraphQL */ `
  mutation ShareListByPhone($phone: String!, $owner: ID!, $listId: ID!) {
    shareListByPhone(phone: $phone, owner: $owner, listId: $listId) {
      owner
      listId
      snapshotId
      listName
      storeName
      hasOpenOrder
      clientId
      orderId
      orderTimestamp
      serviceImage
      itemCount
      numItems
      numUnits
    }
  }
`;
export const addItemToSharedList2 = /* GraphQL */ `
  mutation AddItemToSharedList2(
    $owner: ID!
    $listId: ID!
    $itemId: ID!
    $input: SharedListItemInput2!
  ) {
    addItemToSharedList2(
      owner: $owner
      listId: $listId
      itemId: $itemId
      input: $input
    ) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
      description
      price
      pricePerUnit
      weight
      weightUnit
      displaySize
      link
    }
  }
`;
export const updateItemOnSharedList2 = /* GraphQL */ `
  mutation UpdateItemOnSharedList2(
    $owner: ID!
    $listId: ID!
    $itemId: ID!
    $input: SharedListItemInput2!
  ) {
    updateItemOnSharedList2(
      owner: $owner
      listId: $listId
      itemId: $itemId
      input: $input
    ) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
      description
      price
      pricePerUnit
      weight
      weightUnit
      displaySize
      link
    }
  }
`;
export const deleteItemFromSharedList2 = /* GraphQL */ `
  mutation DeleteItemFromSharedList2($owner: ID!, $listId: ID!, $itemId: ID!) {
    deleteItemFromSharedList2(owner: $owner, listId: $listId, itemId: $itemId) {
      operation
      owner
      listId
      itemId
      listName
      storeName
      position
      name
      image
      unit
      count
      note
      checkedOff
      checkedOffTimestamp
      shopperPosition
      shopperCheckedOff
      shopperCheckedOffTimestamp
      description
      price
      pricePerUnit
      weight
      weightUnit
      displaySize
      link
    }
  }
`;
export const deleteItemFromPastItemsSharedList = /* GraphQL */ `
  mutation DeleteItemFromPastItemsSharedList($itemId: ID!, $store: String!) {
    deleteItemFromPastItemsSharedList(itemId: $itemId, store: $store) {
      id
      dumplingId
      sellerName
      name
      sellerId
      available
      allowed
      description
      upc
      categories
      price
      salePrice
      clubPrice
      pricePerUnit
      salePricePerUnit
      unitType
      weightUnit
      weight
      displaySize
      imageLink
      image
      link
      note
      attributedMedia {
        media {
          ... on MediaImage {
            small {
              width
              height
              path
              mimeType
            }
            medium {
              width
              height
              path
              mimeType
            }
            large {
              width
              height
              path
              mimeType
            }
            xlarge {
              width
              height
              path
              mimeType
            }
            full {
              width
              height
              path
              mimeType
            }
          }
        }
        sourceUrl
        hostPageUrl
        hostPageName
      }
    }
  }
`;
export const updateClientReview = /* GraphQL */ `
  mutation UpdateClientReview($input: ClientReviewInput) {
    updateClientReview(input: $input) {
      id
      quote
      date
      rating
      clientName
      clientImage
      pinned
      hidden
    }
  }
`;
export const connectToClientAccount = /* GraphQL */ `
  mutation ConnectToClientAccount($clientId: ID!) {
    connectToClientAccount(clientId: $clientId) {
      success
      message
    }
  }
`;
export const addPostTipToOrder = /* GraphQL */ `
  mutation AddPostTipToOrder(
    $clientId: ID!
    $orderId: ID!
    $orderTimestamp: String!
    $paymentMethodId: ID
    $amount: Int
  ) {
    addPostTipToOrder(
      clientId: $clientId
      orderId: $orderId
      orderTimestamp: $orderTimestamp
      paymentMethodId: $paymentMethodId
      amount: $amount
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const dismissPostTip = /* GraphQL */ `
  mutation DismissPostTip(
    $clientId: ID!
    $orderId: ID!
    $orderTimestamp: String!
  ) {
    dismissPostTip(
      clientId: $clientId
      orderId: $orderId
      orderTimestamp: $orderTimestamp
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const dismissReview = /* GraphQL */ `
  mutation DismissReview(
    $clientId: ID!
    $orderId: ID!
    $orderTimestamp: String!
  ) {
    dismissReview(
      clientId: $clientId
      orderId: $orderId
      orderTimestamp: $orderTimestamp
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const leaveOrderReview = /* GraphQL */ `
  mutation LeaveOrderReview(
    $orderId: ID!
    $orderTimestamp: String!
    $rating: Int!
    $quote: String!
  ) {
    leaveOrderReview(
      orderId: $orderId
      orderTimestamp: $orderTimestamp
      rating: $rating
      quote: $quote
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const createSosIssue = /* GraphQL */ `
  mutation CreateSosIssue(
    $reason: String!
    $note: String!
    $orderId: String!
    $deliveryTimestamp: String!
  ) {
    createSosIssue(
      reason: $reason
      note: $note
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
    ) {
      reason
      note
      orderId
      deliveryTimestamp
    }
  }
`;
export const bossCreatePassword = /* GraphQL */ `
  mutation BossCreatePassword($password: String) {
    bossCreatePassword(password: $password) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const clientCreatePassword = /* GraphQL */ `
  mutation ClientCreatePassword($password: String) {
    clientCreatePassword(password: $password) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const bossChangePlan = /* GraphQL */ `
  mutation BossChangePlan($id: ID) {
    bossChangePlan(id: $id) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const bossCreatePaymentMethod = /* GraphQL */ `
  mutation BossCreatePaymentMethod($stripeToken: String) {
    bossCreatePaymentMethod(stripeToken: $stripeToken) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      address {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
    }
  }
`;
export const bossDeletePaymentMethod = /* GraphQL */ `
  mutation BossDeletePaymentMethod($id: ID) {
    bossDeletePaymentMethod(id: $id) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      address {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
    }
  }
`;
export const bossSetDefaultPaymentMethod = /* GraphQL */ `
  mutation BossSetDefaultPaymentMethod($id: ID) {
    bossSetDefaultPaymentMethod(id: $id) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      address {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
    }
  }
`;
export const bossExportEarningsReport = /* GraphQL */ `
  mutation BossExportEarningsReport($period: String!) {
    bossExportEarningsReport(period: $period)
  }
`;
export const updateAutoMessage = /* GraphQL */ `
  mutation UpdateAutoMessage($id: ID!, $input: AutoMessageInput!) {
    updateAutoMessage(id: $id, input: $input) {
      id
      type
      title
      action
      message
      defaultMessage
      description
      enabled
      delaySeconds
      defaultDelaySeconds
    }
  }
`;
export const createList = /* GraphQL */ `
  mutation CreateList($storeId: ID) {
    createList(storeId: $storeId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const createOrFindList = /* GraphQL */ `
  mutation CreateOrFindList($storeId: ID) {
    createOrFindList(storeId: $storeId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const deleteList = /* GraphQL */ `
  mutation DeleteList($listId: ID!) {
    deleteList(listId: $listId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const updateItemOnList = /* GraphQL */ `
  mutation UpdateItemOnList(
    $listId: ID!
    $itemId: ID!
    $itemProperties: ItemPropertiesInput!
  ) {
    updateItemOnList(
      listId: $listId
      itemId: $itemId
      itemProperties: $itemProperties
    ) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const deleteItemOnList = /* GraphQL */ `
  mutation DeleteItemOnList($listId: ID!, $itemId: ID!) {
    deleteItemOnList(listId: $listId, itemId: $itemId) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const moveItemBetweenLists = /* GraphQL */ `
  mutation MoveItemBetweenLists(
    $fromListId: ID!
    $toListId: ID!
    $itemId: ID!
  ) {
    moveItemBetweenLists(
      fromListId: $fromListId
      toListId: $toListId
      itemId: $itemId
    ) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const moveAllItemsBetweenLists = /* GraphQL */ `
  mutation MoveAllItemsBetweenLists($fromListId: ID!, $toListId: ID!) {
    moveAllItemsBetweenLists(fromListId: $fromListId, toListId: $toListId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const updateListName = /* GraphQL */ `
  mutation UpdateListName($listId: ID!, $name: String!) {
    updateListName(listId: $listId, name: $name) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const deleteAllItemsFromList = /* GraphQL */ `
  mutation DeleteAllItemsFromList($listId: ID!) {
    deleteAllItemsFromList(listId: $listId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const addItemToList = /* GraphQL */ `
  mutation AddItemToList(
    $listId: ID!
    $itemId: ID!
    $itemProperties: ItemPropertiesInput!
  ) {
    addItemToList(
      listId: $listId
      itemId: $itemId
      itemProperties: $itemProperties
    ) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const createCustomItemForList = /* GraphQL */ `
  mutation CreateCustomItemForList(
    $listId: ID!
    $itemProperties: ItemPropertiesInput!
  ) {
    createCustomItemForList(listId: $listId, itemProperties: $itemProperties) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const shopperUpdateItemOnList = /* GraphQL */ `
  mutation ShopperUpdateItemOnList(
    $listId: ID!
    $itemId: ID!
    $itemProperties: ItemPropertiesInput!
    $owner: ID
    $snapshot: ID
  ) {
    shopperUpdateItemOnList(
      listId: $listId
      itemId: $itemId
      itemProperties: $itemProperties
      owner: $owner
      snapshot: $snapshot
    ) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const shopperUpdateItemsOnList = /* GraphQL */ `
  mutation ShopperUpdateItemsOnList(
    $listId: ID!
    $changedItems: [ItemChanges!]!
    $owner: ID
    $snapshot: ID
  ) {
    shopperUpdateItemsOnList(
      listId: $listId
      changedItems: $changedItems
      owner: $owner
      snapshot: $snapshot
    ) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const shopperCreateCustomItemForList = /* GraphQL */ `
  mutation ShopperCreateCustomItemForList(
    $listId: ID!
    $itemProperties: ItemPropertiesInput!
    $owner: ID
    $snapshot: ID
  ) {
    shopperCreateCustomItemForList(
      listId: $listId
      itemProperties: $itemProperties
      owner: $owner
      snapshot: $snapshot
    ) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const shopperReplaceItemInList = /* GraphQL */ `
  mutation ShopperReplaceItemInList(
    $listId: ID!
    $itemId: ID!
    $replacementProperties: ItemPropertiesInput!
    $owner: ID
    $snapshot: ID
  ) {
    shopperReplaceItemInList(
      listId: $listId
      itemId: $itemId
      replacementProperties: $replacementProperties
      owner: $owner
      snapshot: $snapshot
    ) {
      product {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      listVersion
      listEstimatedTotal {
        id
        displayPrice
      }
      listEstimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const shopperChangeOrderDelivery = /* GraphQL */ `
  mutation ShopperChangeOrderDelivery(
    $orderId: ID!
    $deliveryTimestamp: String!
    $deliveryChanges: OrderDeliveryChangesInput!
  ) {
    shopperChangeOrderDelivery(
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
      deliveryChanges: $deliveryChanges
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const updateLinkedClientAnnotations = /* GraphQL */ `
  mutation UpdateLinkedClientAnnotations(
    $clientId: ID!
    $annotationChanges: LinkedClientAnnotationsInput!
  ) {
    updateLinkedClientAnnotations(
      clientId: $clientId
      annotationChanges: $annotationChanges
    ) {
      id
      note
      image
      noteLastUpdatedTimestamp
      preferencesLastUpdatedTimestamp
      preferencesLastSeenTimestamp
      needsToSeePreferences
    }
  }
`;
export const createInstantPayoutAccount = /* GraphQL */ `
  mutation CreateInstantPayoutAccount(
    $input: CreateInstantPayoutAccountInput!
  ) {
    createInstantPayoutAccount(input: $input) {
      ... on InstantPayoutAccount {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        isVerified
      }
    }
  }
`;
export const updateInstantPayoutAccount = /* GraphQL */ `
  mutation UpdateInstantPayoutAccount(
    $id: ID!
    $input: UpdateInstantPayoutAccountInput!
  ) {
    updateInstantPayoutAccount(id: $id, input: $input) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      isVerified
    }
  }
`;
export const deleteInstantPayoutAccount = /* GraphQL */ `
  mutation DeleteInstantPayoutAccount($id: ID!) {
    deleteInstantPayoutAccount(id: $id) {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      isVerified
    }
  }
`;
export const issueInstantPayout = /* GraphQL */ `
  mutation IssueInstantPayout($input: InstantPayoutInput!) {
    issueInstantPayout(input: $input) {
      id
      balance
      fee
      amount
      arrivalTimestamp
      status
    }
  }
`;
export const requestVirtualCard = /* GraphQL */ `
  mutation RequestVirtualCard {
    requestVirtualCard
  }
`;
export const requestCustomUrl = /* GraphQL */ `
  mutation RequestCustomUrl {
    requestCustomUrl
  }
`;
export const reportIncorrectProductDetails = /* GraphQL */ `
  mutation ReportIncorrectProductDetails($itemId: ID!) {
    reportIncorrectProductDetails(itemId: $itemId)
  }
`;
export const adoptCart = /* GraphQL */ `
  mutation AdoptCart($proposedCartId: ID!, $replaceCart: Boolean) {
    adoptCart(proposedCartId: $proposedCartId, replaceCart: $replaceCart) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const adoptCartWithUpdates = /* GraphQL */ `
  mutation AdoptCartWithUpdates(
    $proposedCartId: ID!
    $cartUpdates: CartInput!
    $replaceCart: Boolean
  ) {
    adoptCartWithUpdates(
      proposedCartId: $proposedCartId
      cartUpdates: $cartUpdates
      replaceCart: $replaceCart
    ) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart($cartUpdates: CartInput!) {
    updateCart(cartUpdates: $cartUpdates) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const addListToCart = /* GraphQL */ `
  mutation AddListToCart($listId: ID!, $replaceCart: Boolean) {
    addListToCart(listId: $listId, replaceCart: $replaceCart) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const removeListFromCart = /* GraphQL */ `
  mutation RemoveListFromCart($listId: ID!) {
    removeListFromCart(listId: $listId) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const createOrderWithCart = /* GraphQL */ `
  mutation CreateOrderWithCart {
    createOrderWithCart {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const deactivateClient = /* GraphQL */ `
  mutation DeactivateClient($id: ID!) {
    deactivateClient(id: $id) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const activateClient = /* GraphQL */ `
  mutation ActivateClient($id: ID!) {
    activateClient(id: $id) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const deactivateShopper = /* GraphQL */ `
  mutation DeactivateShopper($id: ID!) {
    deactivateShopper(id: $id) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const activateShopper = /* GraphQL */ `
  mutation ActivateShopper($id: ID!) {
    activateShopper(id: $id) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const updateShopperFundingInfo = /* GraphQL */ `
  mutation UpdateShopperFundingInfo(
    $id: ID!
    $fundingProfile: String
    $virtualBalance: Int
    $transactionLimit: Int
  ) {
    updateShopperFundingInfo(
      id: $id
      fundingProfile: $fundingProfile
      virtualBalance: $virtualBalance
      transactionLimit: $transactionLimit
    ) {
      id
      fundingProfile
      merchantCategoryId
      transactionLimit
      dailySpendLimit
      dailySpendLimitCeiling
      virtualBalance
      todaysSpend
      fundingPerOrder
    }
  }
`;
export const reopenOrder = /* GraphQL */ `
  mutation ReopenOrder(
    $id: ID!
    $orderId: ID!
    $deliveryTimestamp: String!
    $orderTimestamp: String!
    $clientId: ID!
  ) {
    reopenOrder(
      id: $id
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
      orderTimestamp: $orderTimestamp
      clientId: $clientId
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const uncancelOrder = /* GraphQL */ `
  mutation UncancelOrder(
    $id: ID!
    $orderId: ID!
    $deliveryTimestamp: String!
    $orderTimestamp: String!
    $clientId: ID!
  ) {
    uncancelOrder(
      id: $id
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
      orderTimestamp: $orderTimestamp
      clientId: $clientId
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $shopperId: ID!
    $cardAcctId: ID!
    $transactionType: ID!
    $transactionId: ID!
    $input: TransactionFullInput
  ) {
    updateTransaction(
      shopperId: $shopperId
      cardAcctId: $cardAcctId
      transactionType: $transactionType
      transactionId: $transactionId
      input: $input
    ) {
      id
      update_time
      merchantName
      merchantCity
      merchantState
      merchantZip
      merchantCountry
      merchantLocalTime
      transactionUtcTime
      transactionAmount
      image
      ignore
    }
  }
`;
export const updateOrderPricing = /* GraphQL */ `
  mutation UpdateOrderPricing(
    $orderId: ID!
    $deliveryTimestamp: String!
    $flatFee: String!
    $percent: String!
    $minFee: String!
    $clientPaysPlatform: Boolean!
    $platformDisplayName: String
    $chosenPercent: String
    $chosenFixedGratuity: String
  ) {
    updateOrderPricing(
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
      flatFee: $flatFee
      percent: $percent
      minFee: $minFee
      clientPaysPlatform: $clientPaysPlatform
      platformDisplayName: $platformDisplayName
      chosenPercent: $chosenPercent
      chosenFixedGratuity: $chosenFixedGratuity
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const createSyntheticTransaction = /* GraphQL */ `
  mutation CreateSyntheticTransaction(
    $cardAcctId: ID!
    $input: SyntheticTransactionInput
  ) {
    createSyntheticTransaction(cardAcctId: $cardAcctId, input: $input) {
      id
      update_time
      merchantName
      merchantCity
      merchantState
      merchantZip
      merchantCountry
      merchantLocalTime
      transactionUtcTime
      transactionAmount
      image
      ignore
    }
  }
`;
export const updateShopperSubscription = /* GraphQL */ `
  mutation UpdateShopperSubscription($id: ID, $subscriptionPlan: String) {
    updateShopperSubscription(id: $id, subscriptionPlan: $subscriptionPlan) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const adoptCartFromActivity = /* GraphQL */ `
  mutation AdoptCartFromActivity($activityId: ID!) {
    adoptCartFromActivity(activityId: $activityId) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const assignLinkedOrdersTo = /* GraphQL */ `
  mutation AssignLinkedOrdersTo($input: AssignLinkedOrdersToInput) {
    assignLinkedOrdersTo(input: $input) {
      orders {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        listReferences {
          storeName
          storeImage
          storeId
          id
          listName
          listItemCount
          listVersion
          lastCompletedOrderId
          savedListId
          savedListItemCount
          savedListVersion
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      nextToken
      prevToken
      pageNumber
    }
  }
`;
export const resortDeleteClosedDate = /* GraphQL */ `
  mutation ResortDeleteClosedDate($id: ID!) {
    resortDeleteClosedDate(id: $id)
  }
`;
export const resortCreateClosedDate = /* GraphQL */ `
  mutation ResortCreateClosedDate($date: String!) {
    resortCreateClosedDate(date: $date) {
      id
      date
    }
  }
`;
export const bossConfirmForgotPassword = /* GraphQL */ `
  mutation BossConfirmForgotPassword(
    $phone: String
    $code: String
    $password: String
  ) {
    bossConfirmForgotPassword(phone: $phone, code: $code, password: $password) {
      status
    }
  }
`;
export const clientConfirmForgotPassword = /* GraphQL */ `
  mutation ClientConfirmForgotPassword(
    $phone: String
    $code: String
    $password: String
  ) {
    clientConfirmForgotPassword(
      phone: $phone
      code: $code
      password: $password
    ) {
      status
    }
  }
`;
export const clientCreateAccount = /* GraphQL */ `
  mutation ClientCreateAccount($phone: String) {
    clientCreateAccount(phone: $phone) {
      status
    }
  }
`;
export const bossChangePhone = /* GraphQL */ `
  mutation BossChangePhone(
    $shopperId: ID
    $newPhone: String
    $oldPhone: String
  ) {
    bossChangePhone(
      shopperId: $shopperId
      newPhone: $newPhone
      oldPhone: $oldPhone
    ) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const clientChangePhone = /* GraphQL */ `
  mutation ClientChangePhone(
    $clientId: ID
    $newPhone: String
    $oldPhone: String
  ) {
    clientChangePhone(
      clientId: $clientId
      newPhone: $newPhone
      oldPhone: $oldPhone
    ) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const globalLogoutUser = /* GraphQL */ `
  mutation GlobalLogoutUser($id: ID!, $service: String!, $app: String!) {
    globalLogoutUser(id: $id, service: $service, app: $app) {
      status
    }
  }
`;
export const notifyListChange = /* GraphQL */ `
  mutation NotifyListChange($listId: ID!) {
    notifyListChange(listId: $listId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const notifyCardFundingChange = /* GraphQL */ `
  mutation NotifyCardFundingChange($userId: ID!) {
    notifyCardFundingChange(userId: $userId) {
      userId
      fundingCents
    }
  }
`;
export const remapUser = /* GraphQL */ `
  mutation RemapUser(
    $userType: String!
    $fromId: ID!
    $toId: ID!
    $expiresAt: String
  ) {
    remapUser(
      userType: $userType
      fromId: $fromId
      toId: $toId
      expiresAt: $expiresAt
    ) {
      userId
      phoneNumber
      email
      type
      expiresAt
    }
  }
`;
export const createProposedCartDeepLink = /* GraphQL */ `
  mutation CreateProposedCartDeepLink($cartInput: ProposedCartInput!) {
    createProposedCartDeepLink(cartInput: $cartInput)
  }
`;
