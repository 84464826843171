import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store';
import type { Event } from 'src/models/calendar';
import { filteredLinkedOrders } from 'src/graphql/queries'
import { API, graphqlOperation } from '@aws-amplify/api'
import { format, parse } from 'date-fns';
import { bg } from 'date-fns/locale';

import { resortCreateClosedDate, resortDeleteClosedDate } from 'src/graphql/mutations'
import { resortClosedDates } from 'src/graphql/queries'
import type { Order} from '../API'

import { FilteredLinkedOrdersQuery, QueryFilterInput, FilterOperator, QuerySortInput, SortDirection, ResortClosedDatesQuery, ResortCreateClosedDateMutation, ResortDeleteClosedDateMutation } from '../API'
import dayjs from "dayjs";
import stringToColor from 'src/utils/stringToColor';


interface CalendarState {
  events: Event[];
  isDrawerOpen: boolean;
  selectedEventId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
}

const initialState: CalendarState = {
  events: [],
  isDrawerOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(
      state: CalendarState,
      action: PayloadAction<{ events: Event[] }>
    ) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state: CalendarState, action: PayloadAction<{ event: Event }>) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId?: string }>
    ) {
      const { eventId = null } = action.payload;

      state.isDrawerOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state: CalendarState, action: PayloadAction<{ event: Event }>) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId: string }>
    ) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(
      state: CalendarState,
      action: PayloadAction<{ start: number; end: number }>
    ) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;


export const getEvents =
  (acceptsReferralNetwork: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    let closedDateEvents = [];
    const getClosedDateResponse = await API.graphql(
      graphqlOperation(resortClosedDates)
    ) as {data: ResortClosedDatesQuery};
    const responseClosedDates = getClosedDateResponse.data.resortClosedDates.dates;
    for (const closedDate of responseClosedDates) {
      const closedDateDate = parse(closedDate.date, 'yyyy-MM-dd', new Date());
      let closedDateEvent = {
        id: closedDate.id,
        allDay: true,
        color: '#F95B67',
        description: 'This date is closed for new orders.',
        end: closedDateDate,
        start: closedDateDate,
        title: 'Closed',
      }
      closedDateEvents.push(closedDateEvent)
      let closedDateEventBackground = {
        id: closedDate.id + '-bck', // special designation of a backgound event
        allDay: true,
        color: '#F95B67',
        description: '',
        end: closedDateDate,
        start: closedDateDate,
        title: '',
        display: 'background'
      }
      closedDateEvents.push(closedDateEventBackground)
    }

    // Test code to load in all of the orders
    // const response = await API.graphql(
    //   graphqlOperation(filteredLinkedOrders, { count: 100, nextToken: null, filters: [], text: null, sort: {property: "deliveryTimestamp", direction: "DESC"}})
    // ) as {data: FilteredLinkedOrdersQuery};
    // const orders = response.data.filteredLinkedOrders.orders
    // for (const order of Object.values(orders)) {
    //   var orderDescription = order.firstName + ' ' + order.lastName + ' - ' + order.deliveryAddress.addressLine1
    //   const serviceName = order.serviceRequestList[0]?.serviceName
    //   // If they accept referrals, append the service name so they know who this is from.
    //   if (acceptsReferralNetwork) {
    //     orderDescription += ' - ' + serviceName
    //   }
    //   try {
    //     let event = {
    //       id: order.id,
    //       title: order.firstName + ' ' + order.lastName,
    //       start: dayjs(order.deliveryStartDateTime).toDate(),
    //       end: dayjs(order.deliveryEndDateTime).toDate(),
    //       description: orderDescription,
    //       type: 'order', 
    //       url: `/${location.pathname.split('/')[1]}/management/orders/single/` + order.deliveryTimestamp + '_' + order.id
    //     }
    //     // If they accept referral network orders, colorize the dots according to the service name, or just use the default
    //     if (acceptsReferralNetwork) {
    //       event['color'] = stringToColor(serviceName, false)
    //     }
    //     closedDateEvents.push(event)
    //   }  catch (err) {
    //     console.error(err)
    //   }
    // }

    dispatch(slice.actions.getEvents({events: closedDateEvents}));
  };

export const createEvent =
  (data: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const { allDay, description, end, start, title, color } = data;
    try {
      const response = await API.graphql(
        graphqlOperation(resortCreateClosedDate, { 
          date: format(start, 'yyyy-MM-dd')
        })
      ) as {data: ResortCreateClosedDateMutation};
      const closedDateId = response.data.resortCreateClosedDate.id;
      const closedDateDate = parse(response.data.resortCreateClosedDate.date, 'yyyy-MM-dd', new Date());
      const event = {
        id: closedDateId,
        allDay,
        description,
        end: closedDateDate,
        start: closedDateDate,
        title,
        color
      };
      const eventBackground = {
        id: closedDateId + '-bck',
        allDay,
        description: '',
        end: closedDateDate,
        start: closedDateDate,
        title: '',
        color,
        display: 'background'
      };
      dispatch(slice.actions.createEvent({event: event}));
      dispatch(slice.actions.createEvent({event: eventBackground}));
    } catch (err) {
      throw new Error(err.errors[0].message)
    }
  };

export const selectEvent =
  (eventId?: string): AppThunk =>
  async (dispatch) => {
    // If this is a special background all day event, don't let it get selected as it's not the "real" event
    if (eventId.endsWith('bck')) {
      return
    }
    dispatch(slice.actions.selectEvent({ eventId }));
  };

export const updateEvent =
  (eventId: string, update: any): AppThunk =>
  async (dispatch) => {
    // Now create the new date
    const { allDay, description, end, start, title, color } = update;
    try {
      const response = await API.graphql(
        graphqlOperation(resortCreateClosedDate, { 
          date: format(start, 'yyyy-MM-dd')
        })
      ) as {data: ResortCreateClosedDateMutation};
      const closedDateId = response.data.resortCreateClosedDate.id;
      const closedDateDate = parse(response.data.resortCreateClosedDate.date, 'yyyy-MM-dd', new Date());
      const event = {
        id: closedDateId,
        allDay,
        description,
        end: closedDateDate,
        start: closedDateDate,
        title,
        color
      };
      const eventBackground = {
        id: closedDateId + '-bck',
        allDay,
        description: '',
        end: closedDateDate,
        start: closedDateDate,
        title: '',
        color,
        display: 'background'
      };
      dispatch(slice.actions.createEvent({event: event}));
      dispatch(slice.actions.createEvent({event: eventBackground}));
    } catch (err) {
      throw new Error(err.errors[0].message)
    }

    // TODO: It would be best to have an update function on the api
    var deleteSuccess = false;
    try {
      const response = await API.graphql(
        graphqlOperation(resortDeleteClosedDate, { 
          id: eventId
        })
      ) as {data: ResortDeleteClosedDateMutation};
       
      if (response.data.resortDeleteClosedDate) {
        dispatch(slice.actions.deleteEvent({ eventId }));
        // TODO currently assuming each event has a background event, we can update to check for allDay
        dispatch(slice.actions.deleteEvent({ eventId: eventId + '-bck' }));
        deleteSuccess = true;
      }
    } catch (err) {
      throw new Error(err.errors[0].message)
    }
    // Make sure the delete was a success
    if (!deleteSuccess) {
      throw new Error("Error changing closed date. Please refresh page.")
    }

    // This is logic to do an update if we get that api function
    // let eventToUpdate = { ...update }
    // eventToUpdate['id'] = eventId
    // let eventToUpdateBackground = { ...update }
    // eventToUpdateBackground['id'] = eventId + '-bck'
    // eventToUpdateBackground['title'] = ''
    // eventToUpdateBackground['description'] = ''
    // eventToUpdateBackground['display'] = 'background'

    // dispatch(slice.actions.updateEvent({event: eventToUpdate}));
    // dispatch(slice.actions.updateEvent({event: eventToUpdateBackground}));
  };

export const deleteEvent =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(resortDeleteClosedDate, { 
          id: eventId
        })
      ) as {data: ResortDeleteClosedDateMutation};
       
      if (response.data.resortDeleteClosedDate) {
        dispatch(slice.actions.deleteEvent({ eventId }));
        // TODO currently assuming each event has a background event, we can update to check for allDay
        dispatch(slice.actions.deleteEvent({ eventId: eventId + '-bck' }));
        return
      }
    } catch (err) {
      throw new Error(err.errors[0].message)
    }
    // resposne must have been false
    throw new Error("Error deleting closed date. Please refresh page.")
  };

export const selectRange =
  (start: Date, end: Date): AppThunk =>
  (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };

export const openDrawerPanel = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeDrawerPanel());
};

export default slice;
