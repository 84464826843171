import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Amplify, Auth } from 'aws-amplify';
import { amplifyConfig2 } from '../config';
import { User } from 'src/models/user';
import awsmobile from '../aws-exports';
import { API, graphqlOperation } from '@aws-amplify/api'
import { shopper, getFeatureStates } from '../graphql/queries'
import { ShopperQuery, GetFeatureStatesQuery, FeatureState } from '../API'
import { useIntercom } from 'react-use-intercom';

Amplify.configure(awsmobile)
Amplify.configure(amplifyConfig2)

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state })
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { boot, shutdown, hide, show, update } = useIntercom();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const cognintoUser = await Auth.currentAuthenticatedUser();
        const shopperUser = await API.graphql(graphqlOperation(shopper, {id: cognintoUser.username})) as {data: ShopperQuery};
        const shopperFeatures = await API.graphql(graphqlOperation(getFeatureStates, {id: ["resort-features\*"], isClientRequest: false})) as {data: GetFeatureStatesQuery};
        const shopperFeatureStates = shopperFeatures.data.getFeatureStates
        var allowClosedDates = false;
        for (const featureState of shopperFeatureStates) {
          if (featureState.id === 'resort-features/closed-dates') {
            allowClosedDates = featureState.enabled;
          }
        }

        boot({ userId: shopperUser.data.shopper.id, hideDefaultLauncher: true });
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: shopperUser.data.shopper.id,
              jobtitle: shopperUser.data.shopper.businessName,
              avatar: shopperUser.data.shopper.image,
              //email: user.email,
              name: shopperUser.data.shopper.firstName + ' ' + shopperUser.data.shopper.lastName,
              displayName: shopperUser.data.shopper.displayName,
              acceptsReferralNetwork: shopperUser.data.shopper.acceptsReferralNetwork,
              allowClosedDates: allowClosedDates,
              // role: user.role,
              // location: user.location,
              // username: user.username,
              // posts: user.posts,
              // coverImg: user.coverImg,
              // followers: user.followers,
              // description: user.description
            }
          }
        });
      } catch (error) {
        console.error(error)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName) {
      console.error(`Can't login, "${user.challengeName}" failed.`);
      return;
    }

    try {
      const shopperUser = await API.graphql({...graphqlOperation(shopper, {id: user.username}), authMode: "AMAZON_COGNITO_USER_POOLS"}) as {data: ShopperQuery};
      const shopperFeatures = await API.graphql(graphqlOperation(getFeatureStates, {id: ["resort-features\*"], isClientRequest: false})) as {data: GetFeatureStatesQuery};
      const shopperFeatureStates = shopperFeatures.data.getFeatureStates
      var allowClosedDates = false;
      for (const featureState of shopperFeatureStates) {
        if (featureState.id === 'resort-features/closed-dates') {
          allowClosedDates = featureState.enabled;
        }
      }

      // Temp HACK to allow coordinator accounts to access Gyoza regardless of their subscription as we don't currently have those subscriptions in our system
      const allowedCoordinatorShopperIds =  ['4006eef5-0b38-42aa-b788-6e95660844c2', '659cb27f-4166-4d55-a152-39c4ef268802', 'd60a6e67-d700-46e5-a6b3-dbd6d80c65e1']


      // Crude check that someone is a resort user
      if (!shopperUser.data.shopper.subscriptionPlan.includes('Resort') && !allowedCoordinatorShopperIds.includes(shopperUser.data.shopper.id) ) {
        const error = new Error('Your account does not have permission to use this tool.'); 
        // error.code = 'UserNoPermission'
        throw error
      }
  
      boot({ userId: shopperUser.data.shopper.id, hideDefaultLauncher: true });

      dispatch({
        type: 'LOGIN',
        payload: {
          // TODO - do we need to send isAuthenticated ??
          user: {
            id: shopperUser.data.shopper.id,
            jobtitle: shopperUser.data.shopper.businessName,
            avatar: shopperUser.data.shopper.image,
            //email: user.email,
            name: shopperUser.data.shopper.firstName + ' ' + shopperUser.data.shopper.lastName,
            displayName: shopperUser.data.shopper.displayName,
            acceptsReferralNetwork: shopperUser.data.shopper.acceptsReferralNetwork,
            allowClosedDates: allowClosedDates,
            // role: user.role,
            // location: user.location,
            // username: user.username,
            // posts: user.posts,
            // coverImg: user.coverImg,
            // followers: user.followers,
            // description: user.description
          }
        }
      });
    } catch(err) {
      console.error(err)
      // Crude check for catching the error we created above
      // TODO - Make this more robust, maybe using Error.cause
      if (err.message == 'Your account does not have permission to use this tool.') {
        throw err
      }
      const error = new Error(err.errors[0].message);  // This will display any api access errors imposed on the backend and stop login
      // error.code = 'UserNoPermission'
      throw error
    }
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
