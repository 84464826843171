import { useRef, useContext } from 'react';

import {
  IconButton,
  Badge,
  alpha,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';

import LiveHelpTwoToneIcon from '@mui/icons-material/LiveHelpTwoTone';
import { useIntercom } from 'react-use-intercom';

import { useTranslation } from 'react-i18next';
import { IntercomContext } from 'src/contexts/IntercomContext';


const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function Support() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const ref = useRef<any>(null);
  const { hide, show, isOpen } = useIntercom();
  const {unreadCount} = useContext(IntercomContext);

  const handleToggleOpen = (): void => {
   if (isOpen) {
    hide()
   } else {
    show()
   }
  }

  return (
    <>
      <Tooltip arrow title={t('Help Center')}>
        <Badge
          variant="dot"
          badgeContent={unreadCount}
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          sx={{
            '.MuiBadge-badge': {
              background: theme.colors.error.main
            }
          }}
        >
          <IconButtonWrapper
            color="success"
            ref={ref}
            onClick={handleToggleOpen}
            sx={{
              background: alpha(theme.colors.success.main, 0.2),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.success,

              '&:hover': {
                background: alpha(theme.colors.success.main, 0.3)
              }
            }}
          >
            <LiveHelpTwoToneIcon fontSize="small" />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>
    </>
  );
}

export default Support;

