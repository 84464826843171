import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';

const LoginAmplify: FC = () => {
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate()
  const phoneRegExp = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g

  return (
    <Formik
      initialValues={{
        phone: '',
        password: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        phone: Yup.string()
          .matches(phoneRegExp, t('The phone number provided should be a valid phone number'))
          .max(255)
          .required(t('The phone number field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login("+1" + values.phone, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            navigate('/extended-sidebar/management/orders/list')
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            if (err.code == 'UserNotFoundException' || err.message.includes('permission')) {  // TODO -hack until we can add codes to errors
              setErrors({ phone: err.message });
            } else {
              setErrors({ password: err.message });
            }
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <PatternFormat
            format="(###) ###-####"
            customInput={TextField}
            error={Boolean(touched.phone && errors.phone)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.phone && errors.phone}
            label={t('Phone number')}
            name="phone"
            onBlur={handleBlur}
            onValueChange={values => {
              handleChange({
                target: {
                  name: "phone",  // TODO - I'm kind of hacking this in here, need to learn how this should properly work
                  value: values.value
                }
              });
            }}  
            type="tel"
            //value={values.phone}
            variant="outlined"
          />
          {/* <TextField
            error={Boolean(touched.phone && errors.phone)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.phone && errors.phone}
            label={t('Phone number')}
            name="phone"
            onBlur={handleBlur}
            onChange={handleChange}
            type="tel"
            value={values.phone}
            variant="outlined"
          /> */}
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display={{ xs: 'block', md: 'flex' }}
            justifyContent="space-between"
          >
            <Box display={{ xs: 'block', md: 'flex' }}>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={values.terms}
                    name="terms"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    <Typography variant="body2">
                      {t('I accept the')}{' '}
                      <Link component="a" href="#">
                        {t('terms and conditions')}
                      </Link>
                      .
                    </Typography>
                  </>
                }
              /> */}
            </Box>
            <Link component={RouterLink} to="/account/recover-password">
              <b>{t('Forgot password?')}</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Sign in')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
