import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

// TODO - commented out all the redux-persist stuff.  
// This wasn't quite working, but it's something to look into for the future.
// import { persistStore, persistReducer } from 'redux-persist'
// import {
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import thunk from 'redux-thunk';

// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['calendar', 'mailbox', 'register']
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store = configureStore({
//   reducer: persistedReducer,
//   devTools: true,
//   middleware: (getDefaultMiddleware) =>
//   getDefaultMiddleware({
//       serializableCheck: {
//           ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//   }),
// });

const store = configureStore({
  reducer: rootReducer,
  devTools: true
});

//export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
