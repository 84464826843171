/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const client = /* GraphQL */ `
  query Client($id: ID!) {
    client(id: $id) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const resortClient = /* GraphQL */ `
  query ResortClient($id: ID!) {
    resortClient(id: $id) {
      id
      firstName
      lastName
      phone
      email
      image
      referralSource
      hasShopper
      signupTimestamp
      attnPassword
      deactivated
      hasStreamUser
      preferences {
        id
        surveys {
          id
          completedTimestamp
          name
          responses {
            id
            question
            response
          }
        }
      }
    }
  }
`;
export const clientDefaultAddress = /* GraphQL */ `
  query ClientDefaultAddress($id: ID!) {
    clientDefaultAddress(id: $id) {
      id
      addressName
      addressLine1
      addressLine2
      city
      state
      zipcode
      country
      isDefault
    }
  }
`;
export const clientAddresses = /* GraphQL */ `
  query ClientAddresses($count: Int, $nextToken: String) {
    clientAddresses(count: $count, nextToken: $nextToken) {
      addresses {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      nextToken
    }
  }
`;
export const addressSuggest = /* GraphQL */ `
  query AddressSuggest($query: String!) {
    addressSuggest(query: $query) {
      lat
      lon
      addressLine1
      addressLine2
      city
      state
      zipcode
      country
      freeForm
    }
  }
`;
export const clientPaymentMethods = /* GraphQL */ `
  query ClientPaymentMethods($count: Int, $nextToken: String) {
    clientPaymentMethods(count: $count, nextToken: $nextToken) {
      paymentMethods {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      nextToken
    }
  }
`;
export const clientOrders = /* GraphQL */ `
  query ClientOrders($count: Int, $nextToken: String) {
    clientOrders(count: $count, nextToken: $nextToken) {
      orders {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        listReferences {
          storeName
          storeImage
          storeId
          id
          listName
          listItemCount
          listVersion
          lastCompletedOrderId
          savedListId
          savedListItemCount
          savedListVersion
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      nextToken
      prevToken
      pageNumber
    }
  }
`;
export const clientOrdersFiltered = /* GraphQL */ `
  query ClientOrdersFiltered(
    $orderStatus: String
    $reverse: Boolean
    $count: Int
    $nextToken: String
    $serviceName: String
  ) {
    clientOrdersFiltered(
      orderStatus: $orderStatus
      reverse: $reverse
      count: $count
      nextToken: $nextToken
      serviceName: $serviceName
    ) {
      orders {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        listReferences {
          storeName
          storeImage
          storeId
          id
          listName
          listItemCount
          listVersion
          lastCompletedOrderId
          savedListId
          savedListItemCount
          savedListVersion
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      nextToken
      prevToken
      pageNumber
    }
  }
`;
export const clientPastOrderLists = /* GraphQL */ `
  query ClientPastOrderLists(
    $count: Int
    $nextToken: String
    $serviceName: String
  ) {
    clientPastOrderLists(
      count: $count
      nextToken: $nextToken
      serviceName: $serviceName
    ) {
      pastOrderLists {
        title
        deliveryTimestamp
        categorizedListProducts {
          category
          categoryEmoji
          listProducts {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const clientOrder = /* GraphQL */ `
  query ClientOrder($clientId: ID!, $orderId: ID!, $orderTimestamp: String!) {
    clientOrder(
      clientId: $clientId
      orderId: $orderId
      orderTimestamp: $orderTimestamp
    ) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const allAccessibleOrders = /* GraphQL */ `
  query AllAccessibleOrders($count: Int, $nextToken: String) {
    allAccessibleOrders(count: $count, nextToken: $nextToken) {
      sharedOrders {
        version
        id
        clientId
        shopperId
        firstName
        lastName
        phone
        email
        clientImage
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
      }
      nextToken
    }
  }
`;
export const linkedShopper = /* GraphQL */ `
  query LinkedShopper($id: ID!) {
    linkedShopper(id: $id) {
      id
      shopper {
        id
        firstName
        lastName
        displayName
        deliveryArea
        websiteUrl
        phone
        businessPhone
        email
        bio
        image
        businessName
        availabilityDescription
        announcement
        rating
        repeatClientCount
        orderCount
        reviewCount
        specialtyTags
        tagline
        metaTitle
        metaImage
        metaDescription
        deliveryAreaZipcode
        deliveryAreaZipcodes
        deliveryRadius
        lowestPrice
        subscriptionPlan
        subscriptionPlanDetails {
          id
          textComponents {
            text
            bold
          }
          phases {
            id
            status
          }
        }
        branding {
          coverColor
        }
        typicalPricing {
          summary {
            title
            description
          }
          groceries
          shoppingAndDelivery
          minGratuity
          platform
        }
        allowClientConnection
        stripeConnectAcctExists
        fullSSNRequired
        attnBizProfile
        attnDirectDeposit
        attnCreditCard
        attnServices
        attnPassword
        statusIdentityVerification {
          state
          message
          externalLink
        }
        statusChargeCard {
          state
          message
          externalLink
        }
        optInEngage
        optInZipcode
        deactivated
        acceptsReferralNetwork
      }
      serviceOfferings {
        id
        serviceName
        serviceImage
        serviceDescription
        defaultErrandBusinessNames
        baseFee
        maxErrands
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        disclaimerText
        useSuggestedPricing
      }
      serviceArea {
        id
        city
        state
        zipcode
        description
      }
      serviceTimes {
        dayOfWeek
        startTime
        endTime
      }
    }
  }
`;
export const checkCouponCode = /* GraphQL */ `
  query CheckCouponCode($couponCode: String) {
    checkCouponCode(couponCode: $couponCode) {
      id
      couponCode
      couponValue
      paidByShopper
      validClientIds
      usesPerClient
    }
  }
`;
export const shopper = /* GraphQL */ `
  query Shopper($id: ID!) {
    shopper(id: $id) {
      id
      firstName
      lastName
      displayName
      deliveryArea
      websiteUrl
      phone
      businessPhone
      email
      bio
      image
      businessName
      availabilityDescription
      announcement
      rating
      repeatClientCount
      orderCount
      reviewCount
      specialtyTags
      tagline
      metaTitle
      metaImage
      metaDescription
      deliveryAreaZipcode
      deliveryAreaZipcodes
      deliveryRadius
      lowestPrice
      subscriptionPlan
      subscriptionPlanDetails {
        id
        textComponents {
          text
          bold
        }
        phases {
          id
          status
        }
      }
      branding {
        coverColor
      }
      typicalPricing {
        summary {
          title
          description
        }
        groceries
        shoppingAndDelivery
        minGratuity
        platform
      }
      allowClientConnection
      stripeConnectAcctExists
      fullSSNRequired
      attnBizProfile
      attnDirectDeposit
      attnCreditCard
      attnServices
      attnPassword
      statusIdentityVerification {
        state
        message
        externalLink
      }
      statusChargeCard {
        state
        message
        externalLink
      }
      optInEngage
      optInZipcode
      deactivated
      acceptsReferralNetwork
    }
  }
`;
export const shopperIdentity = /* GraphQL */ `
  query ShopperIdentity {
    shopperIdentity {
      firstName
      lastName
      dob
      phone
      email
      addressLine1
      addressLine2
      city
      state
      zipcode
      ssn
      completed
    }
  }
`;
export const shopperBusinessProfile = /* GraphQL */ `
  query ShopperBusinessProfile($id: ID!) {
    shopperBusinessProfile(id: $id) {
      shopper {
        id
        firstName
        lastName
        displayName
        deliveryArea
        websiteUrl
        phone
        businessPhone
        email
        bio
        image
        businessName
        availabilityDescription
        announcement
        rating
        repeatClientCount
        orderCount
        reviewCount
        specialtyTags
        tagline
        metaTitle
        metaImage
        metaDescription
        deliveryAreaZipcode
        deliveryAreaZipcodes
        deliveryRadius
        lowestPrice
        subscriptionPlan
        subscriptionPlanDetails {
          id
          textComponents {
            text
            bold
          }
          phases {
            id
            status
          }
        }
        branding {
          coverColor
        }
        typicalPricing {
          summary {
            title
            description
          }
          groceries
          shoppingAndDelivery
          minGratuity
          platform
        }
        allowClientConnection
        stripeConnectAcctExists
        fullSSNRequired
        attnBizProfile
        attnDirectDeposit
        attnCreditCard
        attnServices
        attnPassword
        statusIdentityVerification {
          state
          message
          externalLink
        }
        statusChargeCard {
          state
          message
          externalLink
        }
        optInEngage
        optInZipcode
        deactivated
        acceptsReferralNetwork
      }
      serviceOfferings {
        id
        serviceName
        serviceImage
        serviceDescription
        defaultErrandBusinessNames
        baseFee
        maxErrands
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        disclaimerText
        useSuggestedPricing
      }
    }
  }
`;
export const chargeCards = /* GraphQL */ `
  query ChargeCards($count: Int, $nextToken: String) {
    chargeCards(count: $count, nextToken: $nextToken) {
      chargeCards {
        id
        brand
        expirationMonth
        expirationYear
        last4
        status
        message
      }
      nextToken
    }
  }
`;
export const shopperSchedule = /* GraphQL */ `
  query ShopperSchedule($id: ID!) {
    shopperSchedule(id: $id) {
      id
      dailySchedules {
        id
        day
        timeSlots {
          id
          slotStart
          slotEnd
          isActive
          bufferDuration
          bufferMode
        }
      }
    }
  }
`;
export const linkedShopperSchedule = /* GraphQL */ `
  query LinkedShopperSchedule($id: ID!) {
    linkedShopperSchedule(id: $id) {
      id
      dailySchedules {
        id
        day
        timeSlots {
          id
          slotStart
          slotEnd
          isActive
          bufferDuration
          bufferMode
        }
      }
    }
  }
`;
export const shopperScheduleV2 = /* GraphQL */ `
  query ShopperScheduleV2($id: ID) {
    shopperScheduleV2(id: $id) {
      dailySchedules {
        id
        day
        timeSlots {
          id
          slotStart
          slotEnd
          maxOrders
          bufferDuration
          bufferMode
        }
      }
    }
  }
`;
export const linkedShopperScheduleV2 = /* GraphQL */ `
  query LinkedShopperScheduleV2($id: ID) {
    linkedShopperScheduleV2(id: $id) {
      dailySchedules {
        id
        day
        timeSlots {
          id
          slotStart
          slotEnd
          maxOrders
          bufferDuration
          bufferMode
        }
      }
    }
  }
`;
export const shopperClosedDateRanges = /* GraphQL */ `
  query ShopperClosedDateRanges(
    $id: ID
    $input: ShopperClosedDateRangesInput!
  ) {
    shopperClosedDateRanges(id: $id, input: $input) {
      closedDateRanges {
        id
        startDate
        endDate
        description
      }
    }
  }
`;
export const linkedShopperAvailableSchedule = /* GraphQL */ `
  query LinkedShopperAvailableSchedule(
    $id: ID
    $input: ShopperAvailableScheduleInput!
  ) {
    linkedShopperAvailableSchedule(id: $id, input: $input) {
      firstAvailableDayIndex
      firstAvailableTimeSlotOfDayIndex
      dailyAvailableSchedules {
        day
        date
        isClosed
        availableTimeSlots {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
      }
    }
  }
`;
export const shopperScheduleOptions = /* GraphQL */ `
  query ShopperScheduleOptions($id: ID) {
    shopperScheduleOptions(id: $id) {
      defaultMaxOrders
      defaultTimeSlotDuration
      isScheduleAvailable
      defaultBufferDuration
      defaultBufferMode
    }
  }
`;
export const serviceArea = /* GraphQL */ `
  query ServiceArea($id: ID!) {
    serviceArea(id: $id) {
      id
      city
      state
      zipcode
      description
    }
  }
`;
export const serviceTimes = /* GraphQL */ `
  query ServiceTimes($count: Int, $nextToken: String) {
    serviceTimes(count: $count, nextToken: $nextToken) {
      serviceTimes {
        dayOfWeek
        startTime
        endTime
      }
      nextToken
    }
  }
`;
export const getLinkedClient = /* GraphQL */ `
  query GetLinkedClient($clientId: ID!) {
    getLinkedClient(clientId: $clientId) {
      id
      client {
        id
        firstName
        lastName
        phone
        email
        image
        referralSource
        hasShopper
        signupTimestamp
        attnPassword
        deactivated
        hasStreamUser
        preferences {
          id
          surveys {
            id
            completedTimestamp
            name
            responses {
              id
              question
              response
            }
          }
        }
      }
      note
      orderCount
      lastOrderTimestamp
      firstConnectTimestamp
      referralSourceMessage
      clientAnnotation {
        id
        note
        image
        noteLastUpdatedTimestamp
        preferencesLastUpdatedTimestamp
        preferencesLastSeenTimestamp
        needsToSeePreferences
      }
      lastInviteTimestamp
    }
  }
`;
export const linkedClients = /* GraphQL */ `
  query LinkedClients($sortOrder: String, $count: Int, $nextToken: String) {
    linkedClients(sortOrder: $sortOrder, count: $count, nextToken: $nextToken) {
      linkedClients {
        id
        client {
          id
          firstName
          lastName
          phone
          email
          image
          referralSource
          hasShopper
          signupTimestamp
          attnPassword
          deactivated
          hasStreamUser
          preferences {
            id
            surveys {
              id
              completedTimestamp
              name
            }
          }
        }
        note
        orderCount
        lastOrderTimestamp
        firstConnectTimestamp
        referralSourceMessage
        clientAnnotation {
          id
          note
          image
          noteLastUpdatedTimestamp
          preferencesLastUpdatedTimestamp
          preferencesLastSeenTimestamp
          needsToSeePreferences
        }
        lastInviteTimestamp
      }
      nextToken
    }
  }
`;
export const filteredLinkedClients = /* GraphQL */ `
  query FilteredLinkedClients(
    $sort: QuerySortInput
    $filters: [QueryFilterInput!]
    $count: Int
    $nextToken: String
  ) {
    filteredLinkedClients(
      sort: $sort
      filters: $filters
      count: $count
      nextToken: $nextToken
    ) {
      linkedClients {
        id
        client {
          id
          firstName
          lastName
          phone
          email
          image
          referralSource
          hasShopper
          signupTimestamp
          attnPassword
          deactivated
          hasStreamUser
          preferences {
            id
            surveys {
              id
              completedTimestamp
              name
            }
          }
        }
        note
        orderCount
        lastOrderTimestamp
        firstConnectTimestamp
        referralSourceMessage
        clientAnnotation {
          id
          note
          image
          noteLastUpdatedTimestamp
          preferencesLastUpdatedTimestamp
          preferencesLastSeenTimestamp
          needsToSeePreferences
        }
        lastInviteTimestamp
      }
      nextToken
    }
  }
`;
export const linkedClientsSortFilterOptions = /* GraphQL */ `
  query LinkedClientsSortFilterOptions {
    linkedClientsSortFilterOptions {
      sortOptions {
        name
        property
      }
      filterOptions {
        name
        property
        type {
          ... on DateRangeFilterType {
            minTimestamp
            maxTimestamp
          }
          ... on IntRangeFilterType {
            minRange
            minValue
            maxRange
            maxValue
          }
          ... on ChoiceFilterType {
            options {
              name
              property
            }
            isRadioButton
          }
          ... on BooleanFilterType {
            name
          }
        }
      }
    }
  }
`;
export const linkedClientsSortFilterPresets = /* GraphQL */ `
  query LinkedClientsSortFilterPresets {
    linkedClientsSortFilterPresets {
      name
      description
      sort {
        property
        direction
      }
      filters {
        operator
        property
        values
      }
      children {
        name
        description
        sort {
          property
          direction
        }
        filters {
          operator
          property
          values
        }
        children {
          name
          description
          sort {
            property
            direction
          }
          filters {
            operator
            property
            values
          }
          children {
            name
            description
            sort {
              property
              direction
            }
            filters {
              operator
              property
              values
            }
            children {
              name
              description
            }
          }
        }
      }
    }
  }
`;
export const invitedClients = /* GraphQL */ `
  query InvitedClients($sortOrder: String, $count: Int, $nextToken: String) {
    invitedClients(
      sortOrder: $sortOrder
      count: $count
      nextToken: $nextToken
    ) {
      linkedClients {
        id
        client {
          id
          firstName
          lastName
          phone
          email
          image
          referralSource
          hasShopper
          signupTimestamp
          attnPassword
          deactivated
          hasStreamUser
          preferences {
            id
            surveys {
              id
              completedTimestamp
              name
            }
          }
        }
        note
        orderCount
        lastOrderTimestamp
        firstConnectTimestamp
        referralSourceMessage
        clientAnnotation {
          id
          note
          image
          noteLastUpdatedTimestamp
          preferencesLastUpdatedTimestamp
          preferencesLastSeenTimestamp
          needsToSeePreferences
        }
        lastInviteTimestamp
      }
      nextToken
    }
  }
`;
export const directDepositAccounts = /* GraphQL */ `
  query DirectDepositAccounts($count: Int, $nextToken: String) {
    directDepositAccounts(count: $count, nextToken: $nextToken) {
      directDepositAccounts {
        id
        bankName
        accountHolderName
        routingNumber
        last4
        error
        isDefault
      }
      nextToken
    }
  }
`;
export const shopperServices = /* GraphQL */ `
  query ShopperServices($id: ID!) {
    shopperServices(id: $id) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const allPossibleShopperServices = /* GraphQL */ `
  query AllPossibleShopperServices($id: ID!) {
    allPossibleShopperServices(id: $id) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const usedServiceOfferingsList = /* GraphQL */ `
  query UsedServiceOfferingsList($id: ID) {
    usedServiceOfferingsList(id: $id) {
      id
      serviceName
      serviceImage
    }
  }
`;
export const unusedServiceOfferingsList = /* GraphQL */ `
  query UnusedServiceOfferingsList($id: ID) {
    unusedServiceOfferingsList(id: $id) {
      id
      serviceName
      serviceImage
    }
  }
`;
export const serviceOffering = /* GraphQL */ `
  query ServiceOffering($id: ID!, $isNewService: Boolean!) {
    serviceOffering(id: $id, isNewService: $isNewService) {
      id
      serviceName
      serviceImage
      serviceDescription
      defaultErrandBusinessNames
      baseFee
      maxErrands
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      disclaimerText
      useSuggestedPricing
    }
  }
`;
export const shopperCoupons = /* GraphQL */ `
  query ShopperCoupons($count: Int, $nextToken: String) {
    shopperCoupons(count: $count, nextToken: $nextToken) {
      coupons {
        id
        couponCode
        couponValue
        paidByShopper
        validClientIds
        usesPerClient
      }
      nextToken
    }
  }
`;
export const linkedOrders = /* GraphQL */ `
  query LinkedOrders($orderStatus: String, $count: Int, $nextToken: String) {
    linkedOrders(
      orderStatus: $orderStatus
      count: $count
      nextToken: $nextToken
    ) {
      orders {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        listReferences {
          storeName
          storeImage
          storeId
          id
          listName
          listItemCount
          listVersion
          lastCompletedOrderId
          savedListId
          savedListItemCount
          savedListVersion
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      nextToken
      prevToken
      pageNumber
    }
  }
`;
export const filteredLinkedOrders = /* GraphQL */ `
  query FilteredLinkedOrders(
    $sort: QuerySortInput
    $filters: [QueryFilterInput!]
    $text: String
    $count: Int
    $nextToken: String
  ) {
    filteredLinkedOrders(
      sort: $sort
      filters: $filters
      text: $text
      count: $count
      nextToken: $nextToken
    ) {
      orders {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      nextToken
      prevToken
      pageNumber
    }
  }
`;
export const linkedOrder = /* GraphQL */ `
  query LinkedOrder($id: ID!, $deliveryTimestamp: String!) {
    linkedOrder(id: $id, deliveryTimestamp: $deliveryTimestamp) {
      version
      id
      clientId
      shopperId
      shopperInfo {
        image
        displayName
      }
      firstName
      lastName
      phone
      email
      clientImage
      note
      privateNote
      adminNote
      orderStatus
      orderDateTime
      orderTimestamp
      deliveryDate
      deliveryTime
      deliveryTimestamp
      deliveryStartDateTime
      deliveryEndDateTime
      deliveryAddress {
        id
        addressName
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
        isDefault
      }
      orderPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      serviceRequestList {
        serviceId
        serviceName
        image
        errandList {
          businessName
          shoppingItemCount
          shoppingList {
            id
            itemName
            itemNote
            isDone
          }
        }
      }
      pricingModel {
        version
        description
        flatPm {
          fee
          feeTitle
          display
        }
        hourlyPm {
          rate
          billableHours
          minBillableHours
          feeTitle
          display
        }
        percentPm {
          percent
          minFee
          feeTitle
          display
        }
        preTipPm {
          chosenPercent
          percentages
          defaultPercentagesIndex
          footer
          feeTitle
          display
          fixedGratuityOptions
          defaultFixedGratuityOptionsIndex
          chosenFixedGratuity
          gratuityType
          showPreGratuityByDefault
          allowOptionalGratuity
          showCustomGratuity
        }
        clientPlatformPm {
          percent
          feeTitle
          display
        }
        suggestedFlatPm {
          fee
          feeWarningThreshold
          percentages
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedPercentPm {
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
        suggestedFlatPlusPercentPm {
          fee
          feeWarningThreshold
          percent
          minFee
          percentWarningThreshold
          minFeeWarningThreshold
          gratuityType
          gratuityOptions
          defaultGratuityOptionsIndex
          clientPaysPlatform
          showCustomGratuity
          title
          description
        }
      }
      tip
      feeAndPreGratuityDisplay
      deliveryFee
      transactions
      couponCode
      adjustmentDescription
      adjustmentAmount
      adjustments {
        id
        description
        amount
      }
      lineItems {
        deliveryFee
        tipPercentage
        tipAmount
        platformPercentage
        platformAmount
        subtotal
        coupon
        total
        earnings
        stores {
          storeName
          storeAmount
        }
        couponCode
        additionalTipAmount
        adjustmentDescription
        adjustmentAmount
      }
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      review {
        id
        quote
        rating
        timestamp
      }
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
      additionalTipAmount
      additionalTipDismissed
      pricingData {
        preTip
        loan
      }
      externalTransactions {
        id
        description
        amount
        image
        attachment
      }
      assignedTo
    }
  }
`;
export const linkedOrderPaymentsBreakdown = /* GraphQL */ `
  query LinkedOrderPaymentsBreakdown($id: ID!, $deliveryTimestamp: String!) {
    linkedOrderPaymentsBreakdown(
      id: $id
      deliveryTimestamp: $deliveryTimestamp
    ) {
      title
      lineItems {
        key
        value
        type
        metadata {
          txId
          adjustmentDescription
          paymentUTCTimestamp
        }
      }
      final {
        key
        value
        type
        metadata {
          txId
          adjustmentDescription
          paymentUTCTimestamp
        }
      }
    }
  }
`;
export const unusedTransactions = /* GraphQL */ `
  query UnusedTransactions($count: Int, $nextToken: String) {
    unusedTransactions(count: $count, nextToken: $nextToken) {
      transactions {
        id
        update_time
        merchantName
        merchantCity
        merchantState
        merchantZip
        merchantCountry
        merchantLocalTime
        transactionUtcTime
        transactionAmount
        image
        last4
      }
      nextToken
    }
  }
`;
export const orderTransactions = /* GraphQL */ `
  query OrderTransactions($id: ID!, $deliveryTimestamp: String!) {
    orderTransactions(id: $id, deliveryTimestamp: $deliveryTimestamp) {
      id
      update_time
      merchantName
      merchantCity
      merchantState
      merchantZip
      merchantCountry
      merchantLocalTime
      transactionUtcTime
      transactionAmount
      image
      last4
    }
  }
`;
export const orderLineItems = /* GraphQL */ `
  query OrderLineItems($id: ID!, $deliveryTimestamp: String!) {
    orderLineItems(id: $id, deliveryTimestamp: $deliveryTimestamp) {
      deliveryFee
      tipPercentage
      tipAmount
      platformPercentage
      platformAmount
      subtotal
      coupon
      total
      earnings
      stores {
        storeName
        storeAmount
      }
      couponCode
      additionalTipAmount
      adjustmentDescription
      adjustmentAmount
    }
  }
`;
export const presignedPostUrl = /* GraphQL */ `
  query PresignedPostUrl($fileName: String!, $contentType: String!) {
    presignedPostUrl(fileName: $fileName, contentType: $contentType) {
      presignedPostUrl
      presignedPostUrlFields
      url
    }
  }
`;
export const transactionPresignedPostUrl = /* GraphQL */ `
  query TransactionPresignedPostUrl($contentType: String!) {
    transactionPresignedPostUrl(contentType: $contentType) {
      fileName
      presignedPostUrl
      presignedPostUrlFields
      url
    }
  }
`;
export const textToImageLinks = /* GraphQL */ `
  query TextToImageLinks($text: String!) {
    textToImageLinks(text: $text)
  }
`;
export const profile = /* GraphQL */ `
  query Profile($id: ID!) {
    profile(id: $id) {
      userId
      phone
    }
  }
`;
export const getSharedListByServiceName = /* GraphQL */ `
  query GetSharedListByServiceName($serviceName: String) {
    getSharedListByServiceName(serviceName: $serviceName) {
      id
      owner
      listId
      listName
      storeName
      items {
        owner
        listId
        itemId
        catalogId
        position
        name
        image
        unit
        count
        note
        checkedOff
        checkedOffTimestamp
        shopperPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        description
        price
        pricePerUnit
        weight
        weightUnit
        displaySize
        link
        selectedProductOptions {
          name
          selection
        }
      }
      sharedWith
      clientId
      orderId
      orderTimestamp
      serviceImage
    }
  }
`;
export const sharedList = /* GraphQL */ `
  query SharedList($owner: ID!, $listId: ID!, $snapshotId: ID) {
    sharedList(owner: $owner, listId: $listId, snapshotId: $snapshotId) {
      id
      owner
      listId
      listName
      storeName
      items {
        owner
        listId
        itemId
        catalogId
        position
        name
        image
        unit
        count
        note
        checkedOff
        checkedOffTimestamp
        shopperPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        description
        price
        pricePerUnit
        weight
        weightUnit
        displaySize
        link
        selectedProductOptions {
          name
          selection
        }
      }
      sharedWith
      clientId
      orderId
      orderTimestamp
      serviceImage
    }
  }
`;
export const getSharedListByServiceNameOrIds = /* GraphQL */ `
  query GetSharedListByServiceNameOrIds(
    $serviceName: String
    $owner: ID
    $listId: ID
    $snapshotId: ID
  ) {
    getSharedListByServiceNameOrIds(
      serviceName: $serviceName
      owner: $owner
      listId: $listId
      snapshotId: $snapshotId
    ) {
      id
      owner
      listId
      listName
      storeName
      items {
        owner
        listId
        itemId
        catalogId
        position
        name
        image
        unit
        count
        note
        checkedOff
        checkedOffTimestamp
        shopperPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        description
        price
        pricePerUnit
        weight
        weightUnit
        displaySize
        link
        selectedProductOptions {
          name
          selection
        }
      }
      sharedWith
      clientId
      orderId
      orderTimestamp
      serviceImage
    }
  }
`;
export const allSharedListIdentifiers = /* GraphQL */ `
  query AllSharedListIdentifiers($count: Int, $nextToken: String) {
    allSharedListIdentifiers(count: $count, nextToken: $nextToken) {
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      nextToken
    }
  }
`;
export const sharedListIdentifiersForServiceName = /* GraphQL */ `
  query SharedListIdentifiersForServiceName(
    $serviceName: String
    $count: Int
    $nextToken: String
  ) {
    sharedListIdentifiersForServiceName(
      serviceName: $serviceName
      count: $count
      nextToken: $nextToken
    ) {
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      nextToken
    }
  }
`;
export const clientServiceNameSharedLists = /* GraphQL */ `
  query ClientServiceNameSharedLists($count: Int, $nextToken: String) {
    clientServiceNameSharedLists(count: $count, nextToken: $nextToken) {
      sharedListIdentifiers {
        owner
        listId
        snapshotId
        listName
        storeName
        hasOpenOrder
        clientId
        orderId
        orderTimestamp
        serviceImage
        itemCount
        numItems
        numUnits
      }
      nextToken
    }
  }
`;
export const customSuggest = /* GraphQL */ `
  query CustomSuggest($store: String!, $text: String!, $timestamp: String!) {
    customSuggest(store: $store, text: $text, timestamp: $timestamp) {
      text
      timestamp
      results {
        image
        text
      }
    }
  }
`;
export const customSearch = /* GraphQL */ `
  query CustomSearch($store: String!, $text: String!, $timestamp: String!) {
    customSearch(store: $store, text: $text, timestamp: $timestamp) {
      text
      timestamp
      results {
        image
        text
      }
    }
  }
`;
export const productDetails = /* GraphQL */ `
  query ProductDetails($productId: ID!, $listId: ID) {
    productDetails(productId: $productId, listId: $listId) {
      id
      product {
        id
        name
        description
        categories
        price {
          cents
          text
        }
        salePrice {
          cents
          text
        }
        displaySize
        media {
          ... on MediaImage {
            small {
              width
              height
              path
              mimeType
            }
            medium {
              width
              height
              path
              mimeType
            }
            large {
              width
              height
              path
              mimeType
            }
            xlarge {
              width
              height
              path
              mimeType
            }
            full {
              width
              height
              path
              mimeType
            }
          }
        }
        link
        isCustom
      }
      note
      quantity
    }
  }
`;
export const getStreamToken = /* GraphQL */ `
  query GetStreamToken($id: ID!) {
    getStreamToken(id: $id) {
      token
    }
  }
`;
export const productV3Search = /* GraphQL */ `
  query ProductV3Search(
    $store: String!
    $breadcrumbs: [String]!
    $text: String!
    $sortType: SearchResultsSortOptionType
    $count: Int
    $listId: ID
    $maxDepth: Int
  ) {
    productV3Search(
      store: $store
      breadcrumbs: $breadcrumbs
      text: $text
      sortType: $sortType
      count: $count
      listId: $listId
      maxDepth: $maxDepth
    ) {
      store
      category
      text
      results {
        id
        dumplingId
        sellerName
        name
        sellerId
        available
        allowed
        description
        upc
        categories
        price
        salePrice
        clubPrice
        pricePerUnit
        salePricePerUnit
        unitType
        weightUnit
        weight
        displaySize
        imageLink
        image
        link
        note
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
      }
      categories
    }
  }
`;
export const productV3StoreCategories = /* GraphQL */ `
  query ProductV3StoreCategories($store: String!, $breadcrumbs: [String]) {
    productV3StoreCategories(store: $store, breadcrumbs: $breadcrumbs) {
      store
      categories
    }
  }
`;
export const productV3StoreCategories2 = /* GraphQL */ `
  query ProductV3StoreCategories2($store: String!, $breadcrumbs: [String]) {
    productV3StoreCategories2(store: $store, breadcrumbs: $breadcrumbs) {
      store
      categories
    }
  }
`;
export const productV3Suggest = /* GraphQL */ `
  query ProductV3Suggest($store: String!, $text: String!, $timestamp: String!) {
    productV3Suggest(store: $store, text: $text, timestamp: $timestamp) {
      text
      timestamp
      results {
        image
        text
      }
    }
  }
`;
export const genericProductSearch = /* GraphQL */ `
  query GenericProductSearch(
    $store: String!
    $text: String!
    $breadcrumbs: [String]
    $count: Int
    $listId: ID
    $maxDepth: Int
    $limitGroupTypes: [GenericProductResultsGroupType]
  ) {
    genericProductSearch(
      store: $store
      text: $text
      breadcrumbs: $breadcrumbs
      count: $count
      listId: $listId
      maxDepth: $maxDepth
      limitGroupTypes: $limitGroupTypes
    ) {
      store
      title
      subTitle
      description
      externalUrl
      internalUrl
      type
      results {
        id
        dumplingId
        sellerName
        name
        sellerId
        available
        allowed
        description
        upc
        categories
        price
        salePrice
        clubPrice
        pricePerUnit
        salePricePerUnit
        unitType
        weightUnit
        weight
        displaySize
        imageLink
        image
        link
        note
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
      }
    }
  }
`;
export const pastItems = /* GraphQL */ `
  query PastItems($store: String!) {
    pastItems(store: $store) {
      store
      category
      text
      results {
        id
        dumplingId
        sellerName
        name
        sellerId
        available
        allowed
        description
        upc
        categories
        price
        salePrice
        clubPrice
        pricePerUnit
        salePricePerUnit
        unitType
        weightUnit
        weight
        displaySize
        imageLink
        image
        link
        note
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
      }
      categories
    }
  }
`;
export const clientReviews = /* GraphQL */ `
  query ClientReviews($sortOrder: String, $count: Int, $nextToken: String) {
    clientReviews(sortOrder: $sortOrder, count: $count, nextToken: $nextToken) {
      clientReviews {
        id
        quote
        date
        rating
        clientName
        clientImage
        pinned
        hidden
      }
      nextToken
    }
  }
`;
export const shopperReviews = /* GraphQL */ `
  query ShopperReviews($shopperId: ID!, $count: Int, $nextToken: String) {
    shopperReviews(
      shopperId: $shopperId
      count: $count
      nextToken: $nextToken
    ) {
      clientReviews {
        id
        quote
        date
        rating
        clientName
        clientImage
        pinned
        hidden
      }
      nextToken
    }
  }
`;
export const shopperGeoSearch = /* GraphQL */ `
  query ShopperGeoSearch($zipcode: String, $count: Int, $nextToken: String) {
    shopperGeoSearch(zipcode: $zipcode, count: $count, nextToken: $nextToken) {
      announcement
      shoppers {
        id
        firstName
        lastName
        displayName
        deliveryArea
        websiteUrl
        phone
        businessPhone
        email
        bio
        image
        businessName
        availabilityDescription
        announcement
        rating
        repeatClientCount
        orderCount
        reviewCount
        specialtyTags
        tagline
        metaTitle
        metaImage
        metaDescription
        deliveryAreaZipcode
        deliveryAreaZipcodes
        deliveryRadius
        lowestPrice
        subscriptionPlan
        subscriptionPlanDetails {
          id
          textComponents {
            text
            bold
          }
          phases {
            id
            status
          }
        }
        branding {
          coverColor
        }
        typicalPricing {
          summary {
            title
            description
          }
          groceries
          shoppingAndDelivery
          minGratuity
          platform
        }
        allowClientConnection
        stripeConnectAcctExists
        fullSSNRequired
        attnBizProfile
        attnDirectDeposit
        attnCreditCard
        attnServices
        attnPassword
        statusIdentityVerification {
          state
          message
          externalLink
        }
        statusChargeCard {
          state
          message
          externalLink
        }
        optInEngage
        optInZipcode
        deactivated
        acceptsReferralNetwork
      }
      nextToken
    }
  }
`;
export const shopperDetails = /* GraphQL */ `
  query ShopperDetails($shopperId: ID!) {
    shopperDetails(shopperId: $shopperId) {
      id
      shopper {
        id
        firstName
        lastName
        displayName
        deliveryArea
        websiteUrl
        phone
        businessPhone
        email
        bio
        image
        businessName
        availabilityDescription
        announcement
        rating
        repeatClientCount
        orderCount
        reviewCount
        specialtyTags
        tagline
        metaTitle
        metaImage
        metaDescription
        deliveryAreaZipcode
        deliveryAreaZipcodes
        deliveryRadius
        lowestPrice
        subscriptionPlan
        subscriptionPlanDetails {
          id
          textComponents {
            text
            bold
          }
          phases {
            id
            status
          }
        }
        branding {
          coverColor
        }
        typicalPricing {
          summary {
            title
            description
          }
          groceries
          shoppingAndDelivery
          minGratuity
          platform
        }
        allowClientConnection
        stripeConnectAcctExists
        fullSSNRequired
        attnBizProfile
        attnDirectDeposit
        attnCreditCard
        attnServices
        attnPassword
        statusIdentityVerification {
          state
          message
          externalLink
        }
        statusChargeCard {
          state
          message
          externalLink
        }
        optInEngage
        optInZipcode
        deactivated
        acceptsReferralNetwork
      }
      serviceOfferings {
        id
        serviceName
        serviceImage
        serviceDescription
        defaultErrandBusinessNames
        baseFee
        maxErrands
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        disclaimerText
        useSuggestedPricing
      }
      shopperSchedule {
        id
        dailySchedules {
          id
          day
          timeSlots {
            id
            slotStart
            slotEnd
            isActive
            bufferDuration
            bufferMode
          }
        }
      }
    }
  }
`;
export const estimatedCartTotal = /* GraphQL */ `
  query EstimatedCartTotal($owner: ID!, $listId: ID!, $snapshotId: ID) {
    estimatedCartTotal(
      owner: $owner
      listId: $listId
      snapshotId: $snapshotId
    ) {
      owner
      listId
      snapshotId
      cartTotalLines {
        count
        label
        value
      }
      cartTotal {
        count
        label
        value
      }
    }
  }
`;
export const estimatedOrderTotal = /* GraphQL */ `
  query EstimatedOrderTotal(
    $deliveryFee: Int!
    $tip: Int
    $couponCode: String
    $owner: ID!
    $listId: ID!
    $snapshotId: ID
  ) {
    estimatedOrderTotal(
      deliveryFee: $deliveryFee
      tip: $tip
      couponCode: $couponCode
      owner: $owner
      listId: $listId
      snapshotId: $snapshotId
    ) {
      deliveryFee
      tip
      couponCode
      owner
      listId
      snapshotId
      orderTotalLines {
        label
        value
        icon
      }
      orderCoupon {
        label
        value
        icon
      }
      orderTotal {
        label
        value
        icon
      }
      authValue
    }
  }
`;
export const estimatedOrderTotalPreview = /* GraphQL */ `
  query EstimatedOrderTotalPreview($input: OrderPreviewInput) {
    estimatedOrderTotalPreview(input: $input) {
      deliveryFee
      tip
      couponCode
      owner
      listId
      snapshotId
      orderTotalLines {
        label
        value
        icon
      }
      orderCoupon {
        label
        value
        icon
      }
      orderTotal {
        label
        value
        icon
      }
      authValue
    }
  }
`;
export const estimatedOrderTotalClient = /* GraphQL */ `
  query EstimatedOrderTotalClient($orderId: ID, $orderTimestamp: String) {
    estimatedOrderTotalClient(
      orderId: $orderId
      orderTimestamp: $orderTimestamp
    ) {
      deliveryFee
      tip
      couponCode
      owner
      listId
      snapshotId
      orderTotalLines {
        label
        value
        icon
      }
      orderCoupon {
        label
        value
        icon
      }
      orderTotal {
        label
        value
        icon
      }
      authValue
    }
  }
`;
export const postTip = /* GraphQL */ `
  query PostTip($orderId: ID, $orderTimestamp: String) {
    postTip(orderId: $orderId, orderTimestamp: $orderTimestamp) {
      baseDisplayAmount
    }
  }
`;
export const estimatedOrderTotalShopper = /* GraphQL */ `
  query EstimatedOrderTotalShopper($orderId: ID, $deliveryTimestamp: String) {
    estimatedOrderTotalShopper(
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
    ) {
      deliveryFee
      tip
      couponCode
      owner
      listId
      snapshotId
      orderTotalLines {
        label
        value
        icon
      }
      orderCoupon {
        label
        value
        icon
      }
      orderTotal {
        label
        value
        icon
      }
      authValue
    }
  }
`;
export const postTipReferences = /* GraphQL */ `
  query PostTipReferences($clientId: ID!) {
    postTipReferences(clientId: $clientId) {
      orderId
      clientId
      orderTimestamp
    }
  }
`;
export const postTipInfo = /* GraphQL */ `
  query PostTipInfo($clientId: ID!, $orderId: ID!, $orderTimestamp: String!) {
    postTipInfo(
      clientId: $clientId
      orderId: $orderId
      orderTimestamp: $orderTimestamp
    ) {
      orderId
      clientId
      orderTimestamp
      orderTotal
      preTipAmount
      shopperDisplayName
      shopperImage
      serviceName
      serviceImage
    }
  }
`;
export const orderPostGratuityAndReviewInfos = /* GraphQL */ `
  query OrderPostGratuityAndReviewInfos($clientId: ID!) {
    orderPostGratuityAndReviewInfos(clientId: $clientId) {
      showPostGratuity
      showReview
      order {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        listReferences {
          storeName
          storeImage
          storeId
          id
          listName
          listItemCount
          listVersion
          lastCompletedOrderId
          savedListId
          savedListItemCount
          savedListVersion
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      baseAmount
      defaultPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      gratuityType
      gratuityOptions
    }
  }
`;
export const orderPostGratuityInfo = /* GraphQL */ `
  query OrderPostGratuityInfo(
    $clientId: ID!
    $orderId: ID!
    $orderTimestamp: String!
  ) {
    orderPostGratuityInfo(
      clientId: $clientId
      orderId: $orderId
      orderTimestamp: $orderTimestamp
    ) {
      order {
        version
        id
        clientId
        shopperId
        shopperInfo {
          image
          displayName
        }
        firstName
        lastName
        phone
        email
        clientImage
        note
        privateNote
        adminNote
        orderStatus
        orderDateTime
        orderTimestamp
        deliveryDate
        deliveryTime
        deliveryTimestamp
        deliveryStartDateTime
        deliveryEndDateTime
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        orderPaymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        serviceRequestList {
          serviceId
          serviceName
          image
          errandList {
            businessName
            shoppingItemCount
            shoppingList {
              id
              itemName
              itemNote
              isDone
            }
          }
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        tip
        feeAndPreGratuityDisplay
        deliveryFee
        transactions
        couponCode
        adjustmentDescription
        adjustmentAmount
        adjustments {
          id
          description
          amount
        }
        lineItems {
          deliveryFee
          tipPercentage
          tipAmount
          platformPercentage
          platformAmount
          subtotal
          coupon
          total
          earnings
          stores {
            storeName
            storeAmount
          }
          couponCode
          additionalTipAmount
          adjustmentDescription
          adjustmentAmount
        }
        priceBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        earningsBreakdown {
          title
          lineItems {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
          final {
            key
            value
            type
            metadata {
              txId
              adjustmentDescription
              paymentUTCTimestamp
            }
          }
        }
        review {
          id
          quote
          rating
          timestamp
        }
        sharedListIdentifiers {
          owner
          listId
          snapshotId
          listName
          storeName
          hasOpenOrder
          clientId
          orderId
          orderTimestamp
          serviceImage
          itemCount
          numItems
          numUnits
        }
        listReferences {
          storeName
          storeImage
          storeId
          id
          listName
          listItemCount
          listVersion
          lastCompletedOrderId
          savedListId
          savedListItemCount
          savedListVersion
        }
        additionalTipAmount
        additionalTipDismissed
        pricingData {
          preTip
          loan
        }
        externalTransactions {
          id
          description
          amount
          image
          attachment
        }
        assignedTo
      }
      baseAmount
      defaultPaymentMethod {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      gratuityType
      gratuityOptions
    }
  }
`;
export const bossPlans = /* GraphQL */ `
  query BossPlans {
    bossPlans {
      id
      title
      description
      cost
      interval
      mostPopular
    }
  }
`;
export const bossPlanPreview = /* GraphQL */ `
  query BossPlanPreview($id: ID) {
    bossPlanPreview(id: $id) {
      title
      description
      breakdown {
        key
        value
        type
        metadata {
          txId
          adjustmentDescription
          paymentUTCTimestamp
        }
      }
      cta
      paymentRequired
    }
  }
`;
export const bossPaymentMethods = /* GraphQL */ `
  query BossPaymentMethods($count: Int, $nextToken: String) {
    bossPaymentMethods(count: $count, nextToken: $nextToken) {
      paymentMethods {
        id
        brand
        expirationMonth
        expirationYear
        last4
        isDefault
        address {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
      }
      nextToken
    }
  }
`;
export const bossPayouts = /* GraphQL */ `
  query BossPayouts($count: Int, $nextToken: String) {
    bossPayouts(count: $count, nextToken: $nextToken) {
      futurePayoutAmount
      payouts {
        id
        amount
        status
        arrivalTimestamp
      }
      nextToken
    }
  }
`;
export const bossPayoutDetails = /* GraphQL */ `
  query BossPayoutDetails($payoutId: ID!) {
    bossPayoutDetails(payoutId: $payoutId) {
      id
      header {
        key
        value
        type
        metadata {
          txId
          adjustmentDescription
          paymentUTCTimestamp
        }
      }
      payments {
        id
        amount
        client
        date
        orderId
        deliveryTimestamp
        postTip
      }
    }
  }
`;
export const bossInsightsBasic = /* GraphQL */ `
  query BossInsightsBasic {
    bossInsightsBasic {
      profileImage
      ratings {
        score
        reviewCount
        reviewCountBreakdown
      }
      trends {
        charts {
          tab
          maxBarHeight
          bars {
            xLabel
            barLabel
            barHeight
          }
        }
      }
    }
  }
`;
export const bossInsightsMetrics = /* GraphQL */ `
  query BossInsightsMetrics($period: String!) {
    bossInsightsMetrics(period: $period) {
      tab
      dateRange
      earnings
      orderCount
      clientCount
      repeatClientCount
    }
  }
`;
export const bossAvailableSchedule = /* GraphQL */ `
  query BossAvailableSchedule($id: ID, $input: ShopperAvailableScheduleInput!) {
    bossAvailableSchedule(id: $id, input: $input) {
      firstAvailableDayIndex
      firstAvailableTimeSlotOfDayIndex
      dailyAvailableSchedules {
        day
        date
        isClosed
        availableTimeSlots {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
      }
    }
  }
`;
export const bossStripeAccountVerifyLink = /* GraphQL */ `
  query BossStripeAccountVerifyLink($id: ID) {
    bossStripeAccountVerifyLink(id: $id) {
      url
      expiresAt
    }
  }
`;
export const getAutoMessages = /* GraphQL */ `
  query GetAutoMessages($count: Int, $nextToken: String) {
    getAutoMessages(count: $count, nextToken: $nextToken) {
      autoMessages {
        id
        type
        title
        action
        message
        defaultMessage
        description
        enabled
        delaySeconds
        defaultDelaySeconds
      }
      nextToken
    }
  }
`;
export const clientAvailableUpdate = /* GraphQL */ `
  query ClientAvailableUpdate {
    clientAvailableUpdate {
      isRequired
      availableVersion
      note
      infoUrl
    }
  }
`;
export const getListReferences = /* GraphQL */ `
  query GetListReferences($count: Int!, $nextToken: String!) {
    getListReferences(count: $count, nextToken: $nextToken) {
      nextToken
      listReferences {
        storeName
        storeImage
        storeId
        id
        listName
        listItemCount
        listVersion
        lastCompletedOrderId
        savedListId
        savedListItemCount
        savedListVersion
      }
    }
  }
`;
export const getListDetails = /* GraphQL */ `
  query GetListDetails($listId: ID!) {
    getListDetails(listId: $listId) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const getCardFundingOrder = /* GraphQL */ `
  query GetCardFundingOrder($deliveryTimestamp: String!, $orderId: ID!) {
    getCardFundingOrder(
      deliveryTimestamp: $deliveryTimestamp
      orderId: $orderId
    ) {
      userId
      orderId
      deliveryTimestamp
      fundingCents
      fundingDisplayCents
      fundingDisplayDollars
      fundingStatus
      fundingWarning
      contactMessage
      displayFunding
    }
  }
`;
export const getAvailableStores = /* GraphQL */ `
  query GetAvailableStores($shopperId: ID, $request: String) {
    getAvailableStores(shopperId: $shopperId, request: $request) {
      displayShopperFee
      displayRequestFee
      displayRequestBanner
      disclaimer
      storesSections {
        title
        description
        storeReferences {
          storeImage
          storeName
          id
          storeReferencePricing {
            type
            price
          }
        }
      }
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($storeId: ID!) {
    getStore(storeId: $storeId) {
      name
      image
      id
      disclaimer
      isCustom
    }
  }
`;
export const getSearchTerms = /* GraphQL */ `
  query GetSearchTerms(
    $searchText: String!
    $storeId: ID!
    $breadcrumbs: [String!]
  ) {
    getSearchTerms(
      searchText: $searchText
      storeId: $storeId
      breadcrumbs: $breadcrumbs
    ) {
      ... on SearchTermAutocompleteResult {
        completedTerm
      }
      ... on SearchTermAutosuggestResult {
        suggestedTerm
      }
      ... on SearchTermProductResult {
        name
        itemId
      }
      ... on SearchTermInCategoryResult {
        name
        breadcrumbs
      }
    }
  }
`;
export const getDefaultSearchTerms = /* GraphQL */ `
  query GetDefaultSearchTerms($storeId: ID!) {
    getDefaultSearchTerms(storeId: $storeId) {
      ... on SearchTermAutocompleteResult {
        completedTerm
      }
      ... on SearchTermAutosuggestResult {
        suggestedTerm
      }
      ... on SearchTermProductResult {
        name
        itemId
      }
      ... on SearchTermInCategoryResult {
        name
        breadcrumbs
      }
    }
  }
`;
export const getPricingModel = /* GraphQL */ `
  query GetPricingModel($shopperId: ID!, $storeId: ID!) {
    getPricingModel(shopperId: $shopperId, storeId: $storeId) {
      version
      description
      flatPm {
        fee
        feeTitle
        display
      }
      hourlyPm {
        rate
        billableHours
        minBillableHours
        feeTitle
        display
      }
      percentPm {
        percent
        minFee
        feeTitle
        display
      }
      preTipPm {
        chosenPercent
        percentages
        defaultPercentagesIndex
        footer
        feeTitle
        display
        fixedGratuityOptions
        defaultFixedGratuityOptionsIndex
        chosenFixedGratuity
        gratuityType
        showPreGratuityByDefault
        allowOptionalGratuity
        showCustomGratuity
      }
      clientPlatformPm {
        percent
        feeTitle
        display
      }
      suggestedFlatPm {
        fee
        feeWarningThreshold
        percentages
        gratuityType
        gratuityOptions
        defaultGratuityOptionsIndex
        clientPaysPlatform
        showCustomGratuity
        title
        description
      }
      suggestedPercentPm {
        percent
        minFee
        percentWarningThreshold
        minFeeWarningThreshold
        gratuityType
        gratuityOptions
        defaultGratuityOptionsIndex
        clientPaysPlatform
        showCustomGratuity
        title
        description
      }
      suggestedFlatPlusPercentPm {
        fee
        feeWarningThreshold
        percent
        minFee
        percentWarningThreshold
        minFeeWarningThreshold
        gratuityType
        gratuityOptions
        defaultGratuityOptionsIndex
        clientPaysPlatform
        showCustomGratuity
        title
        description
      }
    }
  }
`;
export const getShopperSupportedStores = /* GraphQL */ `
  query GetShopperSupportedStores($shopperId: ID!, $listIds: [ID!]!) {
    getShopperSupportedStores(shopperId: $shopperId, listIds: $listIds) {
      supportedStores {
        storeImage
        storeName
        id
        storeReferencePricing {
          type
          price
        }
      }
      unsupportedStores {
        storeImage
        storeName
        id
        storeReferencePricing {
          type
          price
        }
      }
    }
  }
`;
export const shopperAvailability = /* GraphQL */ `
  query ShopperAvailability(
    $shopperId: ID!
    $shopperAvailableScheduleInput: ShopperAvailableScheduleInput!
  ) {
    shopperAvailability(
      shopperId: $shopperId
      shopperAvailableScheduleInput: $shopperAvailableScheduleInput
    ) {
      id
      shopperAvailabilityDays {
        dayOfWeekType
        shopperAvailabilityType
      }
    }
  }
`;
export const getListNotice = /* GraphQL */ `
  query GetListNotice($listId: ID!) {
    getListNotice(listId: $listId) {
      id
      type
      title
      body
    }
  }
`;
export const shopperAvailabilityDescription = /* GraphQL */ `
  query ShopperAvailabilityDescription(
    $shopperId: ID!
    $shopperAvailableScheduleInput: ShopperAvailableScheduleInput!
  ) {
    shopperAvailabilityDescription(
      shopperId: $shopperId
      shopperAvailableScheduleInput: $shopperAvailableScheduleInput
    ) {
      id
      description
    }
  }
`;
export const shopperGetListDetails = /* GraphQL */ `
  query ShopperGetListDetails($listId: ID!, $owner: ID, $snapshotId: ID) {
    shopperGetListDetails(
      listId: $listId
      owner: $owner
      snapshotId: $snapshotId
    ) {
      id
      savedListId
      listName
      listVersion
      storeName
      storeId
      items {
        id
        catalogId
        media {
          ... on ProductImage {
            imageUrl
          }
          ... on ProductVideo {
            videoUrl
          }
        }
        attributedMedia {
          media {
            ... on MediaImage {
              small {
                width
                height
                path
                mimeType
              }
              medium {
                width
                height
                path
                mimeType
              }
              large {
                width
                height
                path
                mimeType
              }
              xlarge {
                width
                height
                path
                mimeType
              }
              full {
                width
                height
                path
                mimeType
              }
            }
          }
          sourceUrl
          hostPageUrl
          hostPageName
        }
        count
        note
        isCustom
        name
        priceCents
        displayPrice
        salePriceCents
        saleDisplayPrice
        position
        category
        categoryEmoji
        displayUnit
        referenceLink
        deleted
        available
        lastUpdatedTimestamp
        replaces {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        shopperNote
        createdByShopperId
        shopperPosition
        shopperCategoryPosition
        shopperCheckedOff
        shopperCheckedOffTimestamp
        shopperNotAvailable
        selectedProductOptions {
          name
          selection
        }
        packageLineItems {
          productId
          name
          quantity
          note
          selectedProductOptions {
            name
            selection
          }
        }
      }
      estimatedTotal {
        id
        displayPrice
      }
      estimatedCheckoutTotal {
        id
        displayPrice
      }
    }
  }
`;
export const shopperProductDetails = /* GraphQL */ `
  query ShopperProductDetails(
    $productId: ID!
    $listId: ID
    $owner: ID
    $snapshot: ID
  ) {
    shopperProductDetails(
      productId: $productId
      listId: $listId
      owner: $owner
      snapshot: $snapshot
    ) {
      id
      product {
        id
        name
        description
        categories
        price {
          cents
          text
        }
        salePrice {
          cents
          text
        }
        displaySize
        media {
          ... on MediaImage {
            small {
              width
              height
              path
              mimeType
            }
            medium {
              width
              height
              path
              mimeType
            }
            large {
              width
              height
              path
              mimeType
            }
            xlarge {
              width
              height
              path
              mimeType
            }
            full {
              width
              height
              path
              mimeType
            }
          }
        }
        link
        isCustom
      }
      note
      quantity
    }
  }
`;
export const storeSearchOptions = /* GraphQL */ `
  query StoreSearchOptions($storeId: ID!, $maxDepth: Int!) {
    storeSearchOptions(storeId: $storeId, maxDepth: $maxDepth) {
      id
      categories {
        name
        breadcrumbs
        children {
          name
          breadcrumbs
          children {
            name
            breadcrumbs
            children {
              name
              breadcrumbs
              image
              categoryEmoji
            }
            image
            categoryEmoji
          }
          image
          categoryEmoji
        }
        image
        categoryEmoji
      }
      sort {
        name
        type
      }
    }
  }
`;
export const storeListActions = /* GraphQL */ `
  query StoreListActions($storeId: ID!, $listId: ID) {
    storeListActions(storeId: $storeId, listId: $listId) {
      id
      actions {
        ... on SearchStoreListAction {
          name
          buyAgainBreadcrumbs
        }
        ... on BrowseListAction {
          name
          buyAgainBreadcrumbs
        }
        ... on BuyAgainListAction {
          name
          breadcrumbs
        }
        ... on AddItemsListAction {
          name
        }
        ... on PastItemsListAction {
          name
          breadcrumbs
        }
        ... on FilterListAction {
          name
          breadcrumbs
          sortType
        }
        ... on SavedItemsListAction {
          name
          listId
          listItemCount
        }
        ... on BrowseStoreListAction {
          name
        }
      }
    }
  }
`;
export const storeHighlightsCarousels = /* GraphQL */ `
  query StoreHighlightsCarousels($storeId: ID!, $listId: ID!) {
    storeHighlightsCarousels(storeId: $storeId, listId: $listId) {
      id
      carousels {
        id
        name
        products {
          id
          dumplingId
          sellerName
          name
          sellerId
          available
          allowed
          description
          upc
          categories
          price
          salePrice
          clubPrice
          pricePerUnit
          salePricePerUnit
          unitType
          weightUnit
          weight
          displaySize
          imageLink
          image
          link
          note
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
        }
        action {
          ... on ProductFilterAction {
            breadcrumbs
            sortType
          }
        }
      }
    }
  }
`;
export const storeCanonicalCategories = /* GraphQL */ `
  query StoreCanonicalCategories($storeId: ID!, $maxDepth: Int!) {
    storeCanonicalCategories(storeId: $storeId, maxDepth: $maxDepth) {
      id
      categories {
        name
        breadcrumbs
        children {
          name
          breadcrumbs
          children {
            name
            breadcrumbs
            children {
              name
              breadcrumbs
              image
              categoryEmoji
            }
            image
            categoryEmoji
          }
          image
          categoryEmoji
        }
        image
        categoryEmoji
      }
    }
  }
`;
export const shopperGetListChanges = /* GraphQL */ `
  query ShopperGetListChanges($listId: ID!, $owner: ID, $snapshotId: ID) {
    shopperGetListChanges(
      listId: $listId
      owner: $owner
      snapshotId: $snapshotId
    ) {
      id
      fromListVersion
      toListVersion
      changes {
        itemId
        changedBy
        added
        deleted
        diff {
          added {
            key
            value
            dataType
          }
          deleted {
            key
            value
            dataType
          }
          updated {
            key
            old
            new
            dataType
          }
        }
      }
      list {
        id
        savedListId
        listName
        listVersion
        storeName
        storeId
        items {
          id
          catalogId
          media {
            ... on ProductImage {
              imageUrl
            }
            ... on ProductVideo {
              videoUrl
            }
          }
          attributedMedia {
            sourceUrl
            hostPageUrl
            hostPageName
          }
          count
          note
          isCustom
          name
          priceCents
          displayPrice
          salePriceCents
          saleDisplayPrice
          position
          category
          categoryEmoji
          displayUnit
          referenceLink
          deleted
          available
          lastUpdatedTimestamp
          replaces {
            id
            catalogId
            media {
              ... on ProductImage {
                imageUrl
              }
              ... on ProductVideo {
                videoUrl
              }
            }
            attributedMedia {
              sourceUrl
              hostPageUrl
              hostPageName
            }
            count
            note
            isCustom
            name
            priceCents
            displayPrice
            salePriceCents
            saleDisplayPrice
            position
            category
            categoryEmoji
            displayUnit
            referenceLink
            deleted
            available
            lastUpdatedTimestamp
            replaces {
              id
              catalogId
              count
              note
              isCustom
              name
              priceCents
              displayPrice
              salePriceCents
              saleDisplayPrice
              position
              category
              categoryEmoji
              displayUnit
              referenceLink
              deleted
              available
              lastUpdatedTimestamp
              shopperNote
              createdByShopperId
              shopperPosition
              shopperCategoryPosition
              shopperCheckedOff
              shopperCheckedOffTimestamp
              shopperNotAvailable
            }
            shopperNote
            createdByShopperId
            shopperPosition
            shopperCategoryPosition
            shopperCheckedOff
            shopperCheckedOffTimestamp
            shopperNotAvailable
            selectedProductOptions {
              name
              selection
            }
            packageLineItems {
              productId
              name
              quantity
              note
              selectedProductOptions {
                name
                selection
              }
            }
          }
          shopperNote
          createdByShopperId
          shopperPosition
          shopperCategoryPosition
          shopperCheckedOff
          shopperCheckedOffTimestamp
          shopperNotAvailable
          selectedProductOptions {
            name
            selection
          }
          packageLineItems {
            productId
            name
            quantity
            note
            selectedProductOptions {
              name
              selection
            }
          }
        }
        estimatedTotal {
          id
          displayPrice
        }
        estimatedCheckoutTotal {
          id
          displayPrice
        }
      }
    }
  }
`;
export const getInstantPayoutAccounts = /* GraphQL */ `
  query GetInstantPayoutAccounts {
    getInstantPayoutAccounts {
      id
      brand
      expirationMonth
      expirationYear
      last4
      isDefault
      isVerified
    }
  }
`;
export const getAvailableInstantPayoutDetails = /* GraphQL */ `
  query GetAvailableInstantPayoutDetails {
    getAvailableInstantPayoutDetails {
      balance
      fee
      amount
      calculatedTimestamp
    }
  }
`;
export const getFeatureStates = /* GraphQL */ `
  query GetFeatureStates($id: [ID!]!, $isClientRequest: Boolean) {
    getFeatureStates(id: $id, isClientRequest: $isClientRequest) {
      id
      enabled
      state {
        key
        value
        dataType
      }
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID) {
    getCart(id: $id) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const getMarketplaceInfo = /* GraphQL */ `
  query GetMarketplaceInfo($zipcode: String!) {
    getMarketplaceInfo(zipcode: $zipcode) {
      zipcode
      marketplaceType
    }
  }
`;
export const getActivityList = /* GraphQL */ `
  query GetActivityList(
    $currentFilterStores: [String!]!
    $zipcode: String
    $shopperIds: [ID!]
  ) {
    getActivityList(
      currentFilterStores: $currentFilterStores
      zipcode: $zipcode
      shopperIds: $shopperIds
    ) {
      storeFilters {
        name
        imageUri
      }
      upcomingActivities {
        ... on OrderActivity {
          id
          pinCoordinate {
            lat
            lon
          }
          activityType
          performerId
          shopperId
          performerBadge
          performerDisplayName
          performerImageUri
          performerActionText
          timeDisplay
          deliveryStartTimestamp
          deliveryEndTimestamp
          timeslotSpotsRemaining
          timeslotSpotsTotal
          storeDisplayName
          storeDisplayImageUri
          pricingDisplayBase
          pricingDisplayMin
          ctaHeaderDisplay
          discountDisplay
        }
      }
      pastActivities {
        ... on OrderActivity {
          id
          pinCoordinate {
            lat
            lon
          }
          activityType
          performerId
          shopperId
          performerBadge
          performerDisplayName
          performerImageUri
          performerActionText
          timeDisplay
          deliveryStartTimestamp
          deliveryEndTimestamp
          timeslotSpotsRemaining
          timeslotSpotsTotal
          storeDisplayName
          storeDisplayImageUri
          pricingDisplayBase
          pricingDisplayMin
          ctaHeaderDisplay
          discountDisplay
        }
      }
      neCorner {
        lat
        lon
      }
      swCorner {
        lat
        lon
      }
    }
  }
`;
export const getCartFromActivity = /* GraphQL */ `
  query GetCartFromActivity($activityId: ID!) {
    getCartFromActivity(activityId: $activityId) {
      cart {
        id
        type
        clientId
        shopper {
          id
          image
          displayName
          isPro
          clientCount
          repeatClientCount
        }
        deliveryTimeSlot {
          id
          slotStartIso
          slotEndIso
          slotStart
          slotEnd
          maxOrders
          scheduledOrders
          bufferDuration
          bufferMode
          isAvailable
          inBuffer
          inTimeSlot
          availablePromos {
            id
            type
            code
            displayName
            discountFixed
            discountPercent
          }
        }
        preTip {
          percent
          fixed
        }
        note
        services {
          id
          serviceName
          serviceImage
          listIds
        }
        pricingModel {
          version
          description
          flatPm {
            fee
            feeTitle
            display
          }
          hourlyPm {
            rate
            billableHours
            minBillableHours
            feeTitle
            display
          }
          percentPm {
            percent
            minFee
            feeTitle
            display
          }
          preTipPm {
            chosenPercent
            percentages
            defaultPercentagesIndex
            footer
            feeTitle
            display
            fixedGratuityOptions
            defaultFixedGratuityOptionsIndex
            chosenFixedGratuity
            gratuityType
            showPreGratuityByDefault
            allowOptionalGratuity
            showCustomGratuity
          }
          clientPlatformPm {
            percent
            feeTitle
            display
          }
          suggestedFlatPm {
            fee
            feeWarningThreshold
            percentages
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedPercentPm {
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
          suggestedFlatPlusPercentPm {
            fee
            feeWarningThreshold
            percent
            minFee
            percentWarningThreshold
            minFeeWarningThreshold
            gratuityType
            gratuityOptions
            defaultGratuityOptionsIndex
            clientPaysPlatform
            showCustomGratuity
            title
            description
          }
        }
        paymentMethod {
          id
          brand
          expirationMonth
          expirationYear
          last4
          isDefault
          address {
            id
            addressName
            addressLine1
            addressLine2
            city
            state
            zipcode
            country
            isDefault
          }
        }
        deliveryAddress {
          id
          addressName
          addressLine1
          addressLine2
          city
          state
          zipcode
          country
          isDefault
        }
        appliedPromos {
          id
          type
          code
          displayName
          discountFixed
          discountPercent
        }
        estimatedTotals {
          type
          label
          value
        }
        createdOn
        lastUpdated
      }
      errors {
        code
        message
      }
    }
  }
`;
export const customItemImageSearch = /* GraphQL */ `
  query CustomItemImageSearch(
    $query: String!
    $storeId: ID!
    $count: Int
    $offset: Int
  ) {
    customItemImageSearch(
      query: $query
      storeId: $storeId
      count: $count
      offset: $offset
    ) {
      images {
        media {
          ... on MediaImage {
            small {
              width
              height
              path
              mimeType
            }
            medium {
              width
              height
              path
              mimeType
            }
            large {
              width
              height
              path
              mimeType
            }
            xlarge {
              width
              height
              path
              mimeType
            }
            full {
              width
              height
              path
              mimeType
            }
          }
        }
        sourceUrl
        hostPageUrl
        hostPageName
      }
      title
      subTitle
      nextOffset
    }
  }
`;
export const priceAndEarningsBreakdown = /* GraphQL */ `
  query PriceAndEarningsBreakdown(
    $shopperId: ID!
    $orderId: ID!
    $deliveryTimestamp: String!
  ) {
    priceAndEarningsBreakdown(
      shopperId: $shopperId
      orderId: $orderId
      deliveryTimestamp: $deliveryTimestamp
    ) {
      priceBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      earningsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
      paymentsBreakdown {
        title
        lineItems {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
        final {
          key
          value
          type
          metadata {
            txId
            adjustmentDescription
            paymentUTCTimestamp
          }
        }
      }
    }
  }
`;
export const bossForgotPassword = /* GraphQL */ `
  query BossForgotPassword($phone: String) {
    bossForgotPassword(phone: $phone) {
      status
    }
  }
`;
export const clientForgotPassword = /* GraphQL */ `
  query ClientForgotPassword($phone: String) {
    clientForgotPassword(phone: $phone) {
      status
    }
  }
`;
export const loginClientByOrderId = /* GraphQL */ `
  query LoginClientByOrderId($id: ID) {
    loginClientByOrderId(id: $id) {
      status
    }
  }
`;
export const getBossBuddies = /* GraphQL */ `
  query GetBossBuddies {
    getBossBuddies {
      bossBuddyProfiles {
        id
        name
        phone
        permissionLevel
      }
    }
  }
`;
export const resortClosedDates = /* GraphQL */ `
  query ResortClosedDates {
    resortClosedDates {
      dates {
        id
        date
      }
    }
  }
`;
export const getMapviewData = /* GraphQL */ `
  query GetMapviewData($zipcodes: [String]!) {
    GetMapviewData(zipcodes: $zipcodes) {
      geoJsons
      swLonLat
      neLonLat
    }
  }
`;
export const resortCatalog = /* GraphQL */ `
  query ResortCatalog(
    $serviceName: String!
    $breadcrumbs: [String]
    $productId: String
    $searchText: String
  ) {
    resortCatalog(
      serviceName: $serviceName
      breadcrumbs: $breadcrumbs
      productId: $productId
      searchText: $searchText
    ) {
      serviceName
      products {
        id
        name
        description
        image
        thumbnail
        options {
          name
          choices
          required
          placeholderText
        }
        customizations {
          name
          maxLength
          required
          placeholderText
        }
      }
    }
  }
`;
